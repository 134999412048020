

import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Star = ({ star, className, starClassName, img = "/img/star-1-4.svg", star1 = "/img/star-2-2.svg" }) => {
  return (
    <div className={`star ${star} ${className}`}>
      <img className={`img ${starClassName}`} alt="Star" src={star === "star-2" ? star1 : img} />
    </div>
  );
};

Star.propTypes = {
  star: PropTypes.oneOf(["star-1", "star-2"]),
  img: PropTypes.string,
  star1: PropTypes.string,
};
