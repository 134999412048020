import { addToCart } from "../../helpers/addToCart";
import { ConvertToArabicNumbers } from "../../helpers/convertToArabic";
import { removeFromCart } from "../../helpers/removeFromCart";
import './style.css'

export const AddOrMinusCart = ({item, type, className}) => {
  return (
    <div className={`add-to-cart ${className}`}>
      <div className="add-to-cartnumerical">
        <div className="plus-symbol pointer" onClick={()=>addToCart(item?.name, item?.price, item?.name_ar, item?.id, type)}>
          <img
            className="plus-symbol-child"
            alt=""
            src="/img/vector-1@2x.png"
          />
        </div>
        <div className="wrapper">
          <div className="div">{ConvertToArabicNumbers(item?.quantity)}</div>
        </div>
        <div className="plus-symbol pointer" onClick={()=>{removeFromCart(item?.id, type)}}>
          <img
            className="minus-symbol-child"
            alt=""
            src="/img/vector-2@2x.png"
          />
        </div>
      </div>
    </div>
  );
};
