import PropTypes from "prop-types";
import React from "react";
import "./style.css";

export const Text = ({ property1, text = "Patient?", divClassName, dispatchMouse, onclick }) => {
  return (
    <div
      className="text pointer z-2"
      onMouseLeave={() => dispatchMouse('mouse_leave')}
      onMouseEnter={() => {
        dispatchMouse("mouse_enter");
      }}
      onClick={()=>onclick()}
    >
      <div className={`patient  ${property1} ${divClassName}`}>{text}</div>
    </div>
  );
};

Text.propTypes = {
  property1: PropTypes.oneOf(["highlighted", "default"]),
  text: PropTypes.string,
};
