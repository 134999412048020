import i18n from '../../i18n';
import './style.css'
export const StepsBar = ({ currentStep, stepOne, stepTwo, stepThree }) => {
  return (
    <div className="d-flex flex-column margin-bottom6rem">
        <img className={`steps-bar-icon ${i18n?.language === 'ar' ? 'flip-img' : ''}`} alt="steps-bar" src={`/img/step_${currentStep}.png`} />
        <div className="d-flex m-auto bar-text">
          <div className="check-booking-details">{stepOne}</div>
          <div className={`payment-details ${currentStep >= 2 ? 'check-booking-details' : ''}`}>{stepTwo}</div>
          <div className={`checkout ${currentStep === 3 ? 'check-booking-details' : ''}`}>{stepThree}</div>
        </div>
      </div>
  );
};
