import { useEffect, useState } from "react";
import "./style.css";
import { t } from "i18next";
import i18n from "../../i18n";
import GettingService from "../../services/getting.service";

export const Notifications = ({ display, setLoadingFlag }) => {
  const [notifications, setNotifications] = useState([]);

  async function getNotifications(language) {
    await GettingService.getNotifications(setNotifications, language);
  }

  useEffect(()=>{
    setNotifications([]);
    getNotifications(i18n?.language);
  },[i18n?.language])

  return (
    <div className={`flex-column notifications-div ${display} notification-div-${i18n?.language === 'ar' ? 'ar' :'en'}`}>
      <div className="position-relative">
        <img src="./img/Polygon.svg" className={`rectangle-arrow ${i18n?.language === 'ar' ? 'rectangle-arrow-ar' : ''}`} />
      </div>
      <div className="d-flex notification-header">
        <div className="notification-title">{t("yournotifications")}</div>
      </div>
      <div className="notification-content">
        {notifications?.map((item, key) => (
          <div key={`notification-${key}`} className="notification-row">
            <div className={key > 0 ? "mt-2" : ""}>
              <div className="primarylabel">{item?.attributes?.body}</div>
            </div>
          </div>
        ))}
        {
          !notifications?.length && <div>{t("loading")}</div>
        }
      </div>
    </div>
  );
};
