import { SignInUpButton } from "../SignInUpButton";
import { TextInput } from "../TextInput/TextInput";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./style.css";
import { RadioButton } from "../RadioButton/RadioButton";
import { useState } from "react";
import { formatter } from "../../helpers/currencyFormatter";
import { SelectBox } from "../SelectBox/SelectBox";
import { AddAddress } from "../AddAddress/AddAddress";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
import { postData } from "../../services/apiService";

export const CheckoutCard = ({
  totalCalculation,
  setPromoCode,
  setPoints,
  points,
  promoCode,
  removePromo,
  getTotalCalculation,
  currentMethodType,
  setCurrentMethodType,
  serviceType,
  setServiceType,
  currentStep,
  addresses,
  regions,
  setSelectedAddress,
  selectedAddress,
  setLoadingFlag,
  setAddresses,
  type,
}) => {
  const [modalAddress, setModalAddress] = useState();
  const { t } = useTranslation();
  function applyPromo() {
    confirmAlert({
      title: t("address.note"),
      message:
        t("promo.warning"),
      buttons: [
        {
          label: t("proceed"),
          onClick: () => {
            getTotalCalculation(type);
          },
        },
        {
          label: t("cancel"),
          onClick: () => {},
        },
      ],
    });
  }

  function applyPoints() {
    confirmAlert({
      title: t("address.note"),
      message:
        t("points.warning"),
      buttons: [
        {
          label: t("proceed"),
          onClick: () => {
            postData('/apply-points',{points}, true)
          },
        },
        {
          label: t("cancel"),
          onClick: () => {},
        },
      ],
    });
  }

  function removePromo(){
    confirmAlert({
      title: t("address.note"),
      message:
        t("promo.remove.warning"),
      buttons: [
        {
          label: t("proceed"),
          onClick: () => {
            getTotalCalculation(type, true);
          },
        },
        {
          label: t("cancel"),
          onClick: () => {},
        },
      ],
    });
  }

  function addAddress(item) {
    const arr = addresses;
    arr.unshift(item);
    setAddresses(arr);
  }

  return (
    <div className="card-checkout-container">
      <AddAddress
        modalOpen={modalAddress}
        setModalOpen={setModalAddress}
        cities={regions}
        setLoadingFlag={setLoadingFlag}
        AddAddressToArray={addAddress}
      />
      <div className="d-flex promo-div mx-auto width100per">
        {!totalCalculation?.promo_discount && <SignInUpButton
          name={t("applycode")}
          className={"cart-proceed-to-payment h5"}
          onClick={() => applyPromo()}
        />}
        <TextInput
          className={`width100per`}
          divClass={`width75per  m${i18n.language === "ar" ? "e" : "s"}-auto`}
          placeholder={t("promocode")}
          onChange={setPromoCode}
          value={promoCode}
        />{
          totalCalculation?.promo_discount !== 0 && <SignInUpButton
          name={t("remove.promo")}
          className={"btn btn-danger"}
          onClick={() => removePromo()}
        />
        }
      </div>
      {/* <div className="d-flex promo-div mx-auto width100per">
        {!totalCalculation?.points_discount && <SignInUpButton
          name={t("applypoints")}
          className={"cart-proceed-to-payment h5"}
          onClick={() => applyPoints()}
        />}
        <TextInput
          className={"width100per"}
          divClass={`width75per  m${i18n.language === "ar" ? "e" : "s"}-auto`}
          placeholder={t("points")}
          onChange={setPoints}
          value={points}
        />{
          totalCalculation?.promo_discount !== 0 && <SignInUpButton
          name={t("remove.promo")}
          className={"btn btn-danger"}
          onClick={() => removePromo()}
        />
        }
      </div> */}
      <div className="d-flex flex-column checkout-card">
        <div className="d-flex flex-column sub-checkout-card">
          <div className="d-flex">
            <b>{t("bookingreview")}</b>
            <div></div>
          </div>
          <div className="d-flex width100per py-2">
            <div>{t("total")}</div>
            <div className="ms-auto checkout-price-card">
              {formatter().format(totalCalculation?.totalWithoutDiscount)}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column sub-checkout-card">
          <div className="checkout-top width100per">
            <div className="d-flex py-2">
              <b>{t("checkoutsummary")}</b>
              <div></div>
            </div>
            <div className="d-flex width100per py-2">
              <div>{t("plandiscount")}</div>
              <div className="ms-auto checkout-price-card">
                {formatter().format(totalCalculation?.plan_discount)}
              </div>
            </div>
            <div className="d-flex width100per py-2">
              <div>{t("promodiscount")}</div>
              <div className="ms-auto checkout-price-card">
                {formatter().format(totalCalculation?.promo_discount)}
              </div>
            </div>
          </div>
          <div className="d-flex width100per py-2 mb-5">
            <b className="mt-2">{t("total")}</b>
            <b className="ms-auto">
              {formatter().format(totalCalculation?.total)}
            </b>
          </div>
        </div>
        <div className="booking-rows-parent width100per">
          {currentStep === 2 && (type === "lab" || type === "scan") && (
            <div className="booking-details-parent flex-column">
              <div className="payment-p mb-2 width-fit">
                {t("servicetype")}:
              </div>
              <div className="d-flex">
                <RadioButton
                  name="service_type"
                  label={t("atlab")}
                  currentType={serviceType}
                  onChange={() => setServiceType("lab")}
                  className={`m${i18n.language === "ar" ? "s" : "e"}-auto`}
                  type="lab"
                />
                <RadioButton
                  name="service_type"
                  label={t("athome")}
                  currentType={serviceType}
                  onChange={() => setServiceType("home")}
                  className={`m${i18n.language === "ar" ? "s" : "e"}-auto`}
                  type="home"
                />
              </div>
            </div>
          )}

          {currentStep === 2 &&
            (type === "home-visit" ||
              type === "nurse" ||
              type === "lab" ||
              type === "scan" ||
              type === "clinic") && (
              <div className="booking-details-parent flex-column">
                <div className="payment-p mb-2 width-fit">
                  {t("paymentmethod")}:
                </div>
                <div className="d-flex">
                  <RadioButton
                    name="payment_method"
                    label={t("cash")}
                    currentType={currentMethodType}
                    onChange={() => setCurrentMethodType("post_paid")}
                    className={`m${i18n.language === "ar" ? "s" : "e"}-auto`}
                    type="post_paid"
                  />
                  <RadioButton
                    name="payment_method"
                    label={t("creditordebit")}
                    currentType={currentMethodType}
                    onChange={() => setCurrentMethodType("pre_paid")}
                    className={`m${i18n.language === "ar" ? "s" : "e"}-auto`}
                    type="pre_paid"
                  />
                </div>
              </div>
            )}
          {currentStep === 2 &&
            (type === "nurse" ||
              type === "pharmacy" ||
              type === "home-visit" ||
              ((type === "lab" || type === "scan") &&
                serviceType === "home")) && (
              <div className="d-flex flex-column">
                <div className="payment-p mb-2 width-fit">{t("address")}:</div>
                <div className="d-flex">
                  <SelectBox
                    placeholder={t("selectaddress")}
                    items={addresses ? addresses : []}
                    valueIsId={true}
                    type="address"
                    divClass={""}
                    value={selectedAddress}
                    onChange={setSelectedAddress}
                  />
                  <div className="address-add-btn pointer position-relative">
                    <img
                      className="add-circle-outline-icon"
                      alt="add-circle"
                      src="./img/add-circle-outline@2x.png"
                      onClick={() => setModalAddress(true)}
                    />
                  </div>
                </div>
              </div>
            )}
        </div>

        {currentStep === 3 && (
          <div className="booking-rows-parent width100per">
            <div className="booking-details-parent flex-column mb-2 fit-content">
              <div className="payment-p">{t("paymentmethod")}:</div>
              <div>
                {currentMethodType === "pre_paid" ? t("onlinepayment") : t("cash")}
              </div>
            </div>
            {type === "lab" || type === "scan" ? (
              <div className="booking-details-parent flex-column mb-2 fit-content">
                <div className="payment-p">{t("servicetype")}:</div>
                <div>
                  {serviceType === "home" ? t("athome") : t("atlab")}
                </div>
              </div>
            ) : (
              ""
            )}
            {(type === "home-visit" ||
              type === "pharmacy" ||
              type === "nursing" ||
              serviceType === "home") && (
              <div className="booking-details-parent flex-column mb-2 fit-content">
                <div className="payment-p">{t("address")}:</div>
                <div>
                  {
                    addresses?.find((item) => parseInt(item.id) === parseInt(selectedAddress))
                      ?.street
                  }
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
