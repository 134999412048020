import { useNavigate } from "react-router-dom";
import "./style.css";
import { useEffect } from "react";

export const SuccessMessage = ({
  message,
  redirectURL,
  setLoadingFlag,
  cartFlag,
  setSuccess
}) => {
  const navigate = useNavigate();
  useEffect(() => {
    let pharmaCart = localStorage.getItem("pharmacy-cart")
      ? JSON.parse(localStorage.getItem("pharmacy-cart"))
      : [];
    let nursingCart = localStorage.getItem("nurse-cart")
      ? JSON.parse(localStorage.getItem("nurse-cart"))
      : [];
    let labCart = localStorage.getItem("lab-cart")
      ? JSON.parse(localStorage.getItem("lab-cart"))
      : [];
    let scanCart = localStorage.getItem("scan-cart")
      ? JSON.parse(localStorage.getItem("scan-cart"))
      : [];
    setLoadingFlag(false);
    setTimeout(() => {
      if (!redirectURL) {
        if(cartFlag && (scanCart?.length + labCart?.length + pharmaCart?.length + nursingCart?.length) === 0){
          navigate(`/dashboard`,{
            state: {
              selected: "booking"
            }
          })
        }else if(cartFlag){
          if(setSuccess){
            setSuccess(false);
          }
          window.location.href = '/cart'
        }
      }else{
        window.location.href = redirectURL ? redirectURL : "/";
      }

    }, 4000);
  }, [navigate]);

  return (
    <div className="d-flex flex-column h-100vh">
      <div className="m-auto">
        <img className="" alt="success-reg" src="/img/success-reg.gif" />
        <p className="success-p">{message}</p>
      </div>
    </div>
  );
};
