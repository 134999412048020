import { toast } from "react-toastify";
import { getData, postData } from "./apiService";
import TokenService from "./token.service";
import { t } from "i18next";

const login = async (data, navigate, setLoadingFlag) => {
  setLoadingFlag(true);
  await postData("/auth/local", data).then(async (response) => {
    if (response?.jwt) {
      await TokenService.setUser({
        "JWTtoken": response?.jwt,
        "confirmed": false,
        "firstname":response?.user?.username.split('+')[0],
        "lastname": "",
        "patient_id":response?.user?.id
      })

      getUserData(response?.jwt, navigate);

    }

  });
};

const getUserData = async(token, navigate, setUserData, setAddresses, setLoadingFlag) =>{
  if(setLoadingFlag){
    setLoadingFlag(true);
  }
  await getData("/users/me", null, true).then((response)=>{
    TokenService.setUser({
      "firstname": response?.profiles[0]?.firstname,
      "lastname": response?.profiles[0]?.lastname,
      "patient_id": response?.profiles[0]?.patient?.id,
      "phone": response?.mobileNumber,
      "type":  response.type,
      "JWTtoken": token ? token : TokenService.getLocalAccessToken(),
      "confirmed": true,
      "profile": response?.profiles[0],
      "supscription_plan": response?.supscription_plan
    })
    if(!navigate){
      setUserData(response);
      setAddresses(response?.profiles[0]?.patient?.addresses);
    }else{
       navigate('/');
    }
    if(setLoadingFlag){
      setLoadingFlag(false);
    }
   
  }).catch((err)=>{
    if(err?.response?.status === 401){
      window.location.pathname ='/verify';
    };
  })
}

const validateOTP = async (params, navigate, setLoadingFlag) => {
  await getData("/otps", params, true).then(async (response) => {
    const token_two =  TokenService.getLocalAccessToken();
    getUserData(token_two, navigate);
  }).catch((err)=>{
    if(err?.response?.data?.error.message === "Invalid OTP"){
      toast.error(t('invalid.otp'), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        });
    }
  });
} 

const resendOTP = async (setLoadingFlag) => {
  setLoadingFlag(true);
  await postData("/otp/resend",{}, true).then(async (response) => {
    setLoadingFlag(false);
    return response;
  });
} 

const logout = async () => {
  TokenService.removeUser();
  localStorage.setItem(`lab-cart`, []);
  localStorage.setItem(`scan-cart`, []);
  localStorage.setItem(`nurse-cart`, []);
  localStorage.setItem(`pharmacy-cart`, []);
};

const register = async (data, navigate, setLoadingFlag, setWelcomeFlag) => {

  const response = await postData("/auth/local/register", data);
    if (response?.jwt) {
      getUserData(response?.jwt, navigate);
    }
    if (response?.jwt) {
      await TokenService.setUser({
        "JWTtoken": response?.jwt,
        "confirmed": false,
        "firstname": data?.firstname,
        "lastname": data?.lastname,
        "patient_id":response?.user?.id
      })

      getUserData(response?.jwt, navigate);
    }

    setLoadingFlag(false);
    setWelcomeFlag(true);

  return response.user;
};

const forgetPass = async (data, setLoadingFlag) => {
  setLoadingFlag(true);
  await postData('/auth/forgot-password', data).then(()=>{
    setLoadingFlag(false);
    toast.success(t("forgot.success"), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light"
    });
  }).catch(()=>{
    setLoadingFlag(false);
    toast.error(t("forgot.failed"), {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  })
  
};

const getCurrentUser = () => {
  if(!TokenService.getUser()){
    window.location.href= "/login";
  }else{
    return TokenService.getUser();
  }
 
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  getUserData,
  validateOTP,
  resendOTP,
  forgetPass
};

export default AuthService;
