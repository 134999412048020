import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { formatter } from "../../helpers/currencyFormatter";
import { ConvertToArabicNumbers } from "../../helpers/convertToArabic";

export const SubscriptionCard = ({ plan, setModalOpen, dependents }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="frame-main-content">
      <div className="plan-type-selector gap-2">
        <div className="active-plan-indicator">
          <h1 className="plan-type-placeholder">{plan?.name}</h1>
          <div className="plan-activity-indicator">
            <div className="e-g-p-price-frame" />
            <div className="active">{t("active")}</div>
          </div>
        </div>
        <ul className="plan-features">
          <li className="m-0 p-0">
            <div
              className={`${
                i18n.language === "ar" ? "text-end" : "text-start"
              }`}
            >
              {ConvertToArabicNumbers(
                plan?.subscription_plan_option
                  ?.chronic_pharmacy_percentage_discount
              )}
              {`% ${t("plan.offon")} ${t("plan.feature.chronic")}`}
            </div>
          </li>
          <li className="m-0 p-0">
            <div
              className={`${
                i18n.language === "ar" ? "text-end" : "text-start"
              }`}
            >
              {ConvertToArabicNumbers(
                plan?.subscription_plan_option
                  ?.imported_pharmacy_percentage_discount
              )}
              {`% ${t("plan.offon")} ${t("plan.feature.imported")}`}
            </div>
          </li>
          <li className="m-0 p-0 ">
            <div
              className={`${
                i18n.language === "ar" ? "text-end" : "text-start"
              }`}
            >
              {ConvertToArabicNumbers(
                plan?.subscription_plan_option?.pharmacy_percentage_discount
              )}
              {`% ${t("plan.offon")} ${t("plan.feature.other")}`}
            </div>
          </li>
          <li className="m-0 p-0 ">
            <div
              className={`${
                i18n.language === "ar" ? "text-end" : "text-start"
              }`}
            >
              {ConvertToArabicNumbers(
                plan?.subscription_plan_option?.lab_percentage_discount
              )}
              {`% ${t("plan.offon")} ${t("plan.feature.lab")}`}
            </div>
          </li>
          <li className="m-0 p-0">
            <div
              className={`${
                i18n.language === "ar" ? "text-end" : "text-start"
              }`}
            >
              {ConvertToArabicNumbers(
                plan?.subscription_plan_option?.scan_percentage_discount
              )}
              {`% ${t("plan.offon")} ${t("plan.feature.scan")}`}
            </div>
          </li>
          <li className="m-0 p-0 ">
            <div
              className={`${
                i18n.language === "ar" ? "text-end" : "text-start"
              }`}
            >
              {ConvertToArabicNumbers(
                plan?.subscription_plan_option?.doctor_percentage_discount
              )}
              {`% ${t("plan.offon")} ${t("plan.feature.doctor")}`}
            </div>
          </li>
          <li className="m-0 p-0">
            <div
              className={`${
                i18n.language === "ar" ? "text-end" : "text-start"
              }`}
            >
              {ConvertToArabicNumbers(
                plan?.subscription_plan_option?.free_teleconsultation
              )}
              {`% ${t("plan.offon")} ${t("plan.feature.tele")}`}
            </div>
          </li>
        </ul>
        {plan?.type === "family" ? (
          <>
            <div className="payment-method-label p-0">
              <div className="expiry-date-frame">
                <div className="family-members">
                  <b>{t("familymembers")}</b>
                </div>
                <div className="family-memebers"></div>
                {plan?.maxNumberOfDependents > dependents?.length && (
                  <div
                    className="add-family-member"
                    onClick={() => setModalOpen(true)}
                  >
                    <div className="image-placeholder" />
                    <h3 className="amount-label">+</h3>
                  </div>
                )}
              </div>
            </div>
            <ul className="plan-features">
              {dependents?.map((item, key) => (
                <li className="m-0 p-0" key={`dependant-${key}`}>
                  <div
                    className={`${
                      i18n.language === "ar" ? "text-end" : "text-start"
                    }`}
                  >
                    {`${item?.profiles[0]?.firstname} ${item?.profiles[0]?.lastname}`}
                  </div>
                </li>
              ))}
            </ul>
          </>
        ) : (
          ""
        )}
      </div>
      <div className="billing-frame-group">
        <div className="egp">
          <span className="egp-txt">
            <b>
              {formatter(i18n?.language)?.format(
                plan?.subscription_plan_option?.fee
              )}
            </b>
          </span>
        </div>
        <div className="per-monthly-price-frame">
          <div className="per-month">
            {`${t("per")} ${
              plan?.subscription_plan_option?.duration === "yearly"
                ? t("year")
                : t("month")
            }`}
          </div>
        </div>
      </div>
    </div>
  );
};
