import { SECRET_STORAGE_KEY } from "../shares/config";
import CryptoJS from 'crypto-js';

const encrypt = async (data) => {
  return data ? CryptoJS.AES.encrypt(JSON.stringify(data), SECRET_STORAGE_KEY,
  {
     keySize: 128 / 8,
     iv: SECRET_STORAGE_KEY,
     mode: CryptoJS.mode.CBC,
     padding: CryptoJS.pad.Pkcs7
   }).toString() : null;
};

const decrypt =  (data) => {
 return (data ? JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.AES.decrypt(data,  SECRET_STORAGE_KEY, 
    {
        keySize: 128 / 8,
        iv: SECRET_STORAGE_KEY,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7
      }))) : null);

};

const encryptionService = {
  encrypt,
  decrypt,
};

export default encryptionService;
