import React, { useEffect, useState } from "react";
import GettingService from "../../services/getting.service";
import { useTranslation } from "react-i18next";
import Moment from "moment";
import { BookingDetails } from "../BookingDetails/BookingDetails";
import { DashboardBookingCard } from "../DashboardBookingCard/DashboardBookingCard";
import { DashboardOrderCard } from "../DashboardOrderCard/DashboardOrderCard";
import VideoConsultation from "../VideoConsultation/VideoConsultation";
import { BookingModal } from "../BookingModal/BookingModal";

export const BookingTab = ({ setLoadingFlag, orderTab, userData }) => {
  const { i18n, t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState({});
  const [offset, setOffset] = useState(1);
  const [selectedFilter, setSelectedFilter] = useState("orders");
  const [bookingModalOpen, setBookingModalOpen] = useState(false);
  const [videoConfig, setVideoConfig] = useState(null);
  const [videoCall, setVideoCall] = useState(false);

  Moment.locale(i18n.language);

  async function getNeededData(filter) {
    if (filter) {
      setSelectedFilter(filter);
    }

    await GettingService.getOrders(setOrders, offset, filter, setLoadingFlag);
  
  }

  function viewDetailsModal(item) {
    setSelectedOrder(item);
    setDetailsOpen(true);
  }

  useEffect(() => {
    getNeededData(orderTab ? orderTab : "orders");
  }, [offset]);

  return (
    <div className="d-flex flex-column ms-5 height86vh">
      <BookingDetails
        modalOpen={detailsOpen}
        setModalOpen={setDetailsOpen}
        item={selectedOrder}
        setBookingModalOpen={setBookingModalOpen}
        setLoadingFlag={setLoadingFlag}
        setVideoConfig={setVideoConfig}
        videoConfig={videoConfig}
        setVideoCall={setVideoCall}
      />
      {videoConfig && videoCall ? (
        <VideoConsultation
          videoConfig={videoConfig}
          modalOpen={videoCall}
          setModalOpen={setVideoCall}
          userId={userData?.id}
        />
      ) : (
        ""
      )}
      <BookingModal
        modalOpen={bookingModalOpen}
        setModalOpen={setBookingModalOpen}
        item={selectedOrder}
        originalType={selectedOrder?.type}
        setLoadingFlag={setLoadingFlag}
      />
      <div className="page-dashboard-container">
        <div className="d-flex gap-3 mt-3 px-3">
          <div
            onClick={() => getNeededData("appointment")}
            className={`d-flex gap-1 time-slot fit-content ${
              selectedFilter === "appointment" ? "selected-time-slot" : ""
            } pointer`}
          >
            {t("bookinghistory")}
          </div>
          <div
            className={`d-flex gap-1 time-slot fit-content pointer ${
              selectedFilter === "orders" ? "selected-time-slot" : ""
            }`}
            onClick={() => getNeededData("orders")}
          >
            {t("ordershistory")}
          </div>
        </div>
        <div className="d-flex flex-wrap gap-3 justify-content-sm-around">
          {orders.map((item, key) =>
            item?.attributes?.type === "appointment" ? (
              <DashboardBookingCard
                item={item}
                key={`${key}-booking-card`}
                viewDetailsModal={viewDetailsModal}
              />
            ) : (
              <DashboardOrderCard
                item={item}
                key={`${key}-booking-card`}
                viewDetailsModal={viewDetailsModal}
              />
            )
          )}
        </div>
      </div>
    </div>
  );
};
