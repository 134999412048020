import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Star } from "../Star";
import { Text } from "../Text";
import "./style.css";

export const PatientStar = ({
  stateProp,
  className,
  starStar = "/img/star-1.svg",
  starStarClassName,
  onClick,
  title
}) => {
  const [state, dispatch] = useReducer(reducer, {
    state: stateProp || "default",
  });

  return (
    <div className={`patient-star state-${state.state} ${className}`}>
      <Text
        divClassName={`${state.state === "on-hover" && "class-4"}`}
        property1={state.state === "on-hover" ? "highlighted" : "default"}
        text={title + "?"}
        dispatchMouse={dispatch}
        onclick={onClick}
      />
      <Star
        className={`${state.state === "on-hover" ? "class-5" : "class-6"}`}
        img={starStar}
        star={state.state === "on-hover" ? "star-2" : "star-1"}
        star1={state.state === "on-hover" ? "/img/star-2-1.svg" : undefined}
        starClassName={starStarClassName}
      />
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        state: "on-hover",
      };

    case "mouse_leave":
      return {
        ...state,
        state: "default",
      };
    default:
      break;
  }

  return state;
}

PatientStar.propTypes = {
  stateProp: PropTypes.oneOf(["on-hover", "default"]),
  starStar: PropTypes.string,
};
