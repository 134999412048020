import { useNavigate } from "react-router-dom";
import { SignInUpButton } from "../SignInUpButton";
import "./style.css";
import { t } from "i18next";
import i18n from "../../i18n";

export const CartFooter = ({ onClick , btnName, disabled }) => {
  const navigate = useNavigate();
  return (
    <div className="d-flex mt-auto cart-footer mb-3">
      <div className="cart-return-button pointer" onClick={()=>navigate('/')}>
        <img className="arrow-back-icon" alt="" src="/img/arrow-back@2x.png" />
        <div className="total">{t("backtohome")}</div>
      </div>
      <SignInUpButton state={disabled ? 'greyed-out' : 'default'} name={btnName} className={`cart-proceed-to-payment h3 m${i18n.language === 'ar' ? "e" : "s"}-auto`} onClick={()=>onClick()} img={"/img/arrow-forward-ios@2x.png"} />
    </div>
  );
};
