import { CartCardItem } from "../CartCardItem/CartCardItem";

export const CartItems = ({ items, type }) => {
  return (
    <div className="d-flex flex-column width62per card-checkout-container">
      {items?.map((item, key) => (
        <CartCardItem key={`cart-item-${key}`} item={item} type={type}/>
      ))}
    </div>
  );
};
