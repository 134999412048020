import axios from "axios";
import TokenService from "./token.service";

const API_BASE_URL = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' || ( window.location.host !== 'doxx.care' && window.location.host !== 'www.doxx.care' ) ? 'https://api-dev.doxx.care/api' : 'https://api.doxx.care/api';
const headers = {
  "Content-Type": "application/json",
};
const apiService = axios.create({
  baseURL: API_BASE_URL,
  headers,
});

export const getData = async (url, params, token_flag) => {
  try {
    let config = {};
    if (token_flag === true) {
      const promiseToken = TokenService.getLocalAccessToken();
      config = {
        headers: { Authorization: `Bearer ${promiseToken}` },
        params,
      };
    } else {
      config = { params };
    }

    const response = await apiService.get(url, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const postData = async (url, data, token_flag, multipartFlag) => {
  try {
    let config = {};
    if (token_flag) {
      const token_two = TokenService.getLocalAccessToken();
      config = {
        headers: {
          Authorization: `Bearer ${token_two}`,
          "content-type": multipartFlag
            ? "multipart/form-data"
            : "application/json",
        },
      };
    }

    const response = await apiService.post(url, data, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const editData = async (url, id, data) => {
  try {
    let config = {};
    const token = TokenService.getLocalAccessToken();
    config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    const response = await apiService.put(`${url}/${id}`, data, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteData = async (url, id) => {
  try {
    const token = TokenService.getLocalAccessToken();
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    console.log(`${url}/${id}`);
    const response = await apiService.delete(`${url}/${id}`, config);
    return response.data;
  } catch (error) {
    throw error;
  }
};
