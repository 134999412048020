import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "./style.css";

export const SubscriptionHeader = ({ navTitle, navUrl, icon, type }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-wrap m-4">
      <div className="title width60per">
        <b className="pricing-plans width-fit black-text">{t("subscription.pricingplans")}</b>
        <i className="for-less-than-container">
          <p className="for-less-than black-text">
            {t("subscription.pricingdescription")}
          </p>
        </i>
      </div>
      <div
        className={`mt-4 ${type === 'personal' ? "family-plans-button ": "personal-plans-button "} pointer`}
        onClick={() => navigate(navUrl)}
      >
        <img className="person-icon" alt="person" src={icon} />
        <b className="personal-plans">{navTitle}</b>
        <img
          className="arrow-back-icon1"
          alt="arrow"
          src={`${type === 'personal' ? './img/arrow-back@2x-personal.png' : './img/arrow_back.svg'}`}
        />
      </div>
    </div>
  );
};
