import { t } from "i18next";
import { toast } from "react-toastify";

export const removeFromCart = (id, type) => {
    let cartArr = localStorage.getItem(`${type}-cart`) ? JSON.parse(localStorage.getItem(`${type}-cart`)) : [];
    let filtered_arr = cartArr;
    const current = filtered_arr?.find(o => id === o.id );
 
    if(current && (current?.quantity > 1)) current.quantity = parseInt(current.quantity)-1;
    else filtered_arr = cartArr.filter((item)=>item.id !== id );
  localStorage.setItem(`${type}-cart`, JSON.stringify(filtered_arr));
  toast(t("itemRemovedFromCart"));
  window.dispatchEvent(new Event("storage"));
  };