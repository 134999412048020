import AgoraRTC from "agora-rtc-react";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import ReactDOM from "react-dom";
import { agoraAppId, agoraChannel } from "../../shares/config";
import ReactModal from "react-modal";
import { SignInUpButton } from "../SignInUpButton";
import { t } from "i18next";

export const rtc = {
  client: null,
  localAudioTrack: null,
  localVideoTrack: null,
};

export default function VideoConsultation({
  videoConfig,
  userId,
  modalOpen,
  setModalOpen,
}) {
  const options = {
    appId: agoraAppId,
    token: videoConfig?.patientToken,
  };
  async function handleSubmit() {
    try {
      setJoined(true);
      rtc.client = AgoraRTC.createClient();
      await rtc.client.join(
        options.appId,
        videoConfig?.sessionId,
        videoConfig?.patientToken,
        userId
      );

      // Create an audio track from the audio captured by a microphone
      rtc.localAudioTrack = await AgoraRTC.createMicrophoneAudioTrack();
      // Create a video track from the video captured by a camera
      rtc.localVideoTrack = await AgoraRTC.createCameraVideoTrack();

      rtc.localVideoTrack.play("local-stream");

      rtc.client.on("user-published", async (user, mediaType) => {
        console.log(user);
        // Subscribe to a remote user
        await rtc.client.subscribe(user);
        console.log("subscribe success");
        // console.log(user);

        if (mediaType === "video" || mediaType === "all") {
          // Get `RemoteVideoTrack` in the `user` object.
          const remoteVideoTrack = user.videoTrack;
          console.log(remoteVideoTrack);

          // Dynamically create a container in the form of a DIV element for playing the remote video track.
          const PlayerContainer = React.createElement("div", {
            id: user.uid,
            className: "stream",
          });
          ReactDOM.render(
            PlayerContainer,
            document.getElementById("remote-stream")
          );

          user.videoTrack.play(`${user.uid}`);
        }

        if (mediaType === "audio" || mediaType === "all") {
          // Get `RemoteAudioTrack` in the `user` object.
          const remoteAudioTrack = user.audioTrack;
          // Play the audio track. Do not need to pass any DOM element
          remoteAudioTrack.play();
        }
      });

      rtc.client.on("user-unpublished", (user) => {
        // Get the dynamically created DIV container
        const playerContainer = document.getElementById(user.uid);
        console.log(playerContainer);
        // Destroy the container
        playerContainer.remove();
      });

      // Publish the local audio and video tracks to the channel
      await rtc.client.publish([rtc.localAudioTrack, rtc.localVideoTrack]);

      console.log("publish success!");
    } catch (error) {
      console.error(error);
    }
  }

  async function handleLeave() {
    try {
      const localContainer = document.getElementById("local-stream");
      setModalOpen(false);

      rtc.localAudioTrack.close();
      rtc.localVideoTrack.close();

      setJoined(false);
      localContainer.textContent = "";

      // Traverse all remote users
      rtc.client.remoteUsers.forEach((user) => {
        // Destroy the dynamically created DIV container
        const playerContainer = document.getElementById(user.uid);
        playerContainer && playerContainer.remove();
      });

      // Leave the channel
      await rtc.client.leave();
    } catch (err) {
      console.error(err);
    }
  }
  const [joined, setJoined] = useState(false);
  const channelRef = useRef(videoConfig?.sessionId);
  const remoteRef = useRef("");
  const leaveRef = useRef("");

  useEffect(() => {
    handleSubmit();
  }, []);

  const customStyles = {
    content: {
      position: "absolute",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(238, 238, 238)",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "67%",
      height: "fit-content",
      maxWidth: "700px",
      top: "3rem",
      marginRight: "auto",
      marginLeft: "auto",
    },
    overlay: {
      position: "fixed",
      zIndex: 1020,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };
  return (
    <ReactModal
      isOpen={modalOpen}
      contentLabel="Booking"
      style={customStyles}
      ariaHideApp={false}
    >
      <div class="col-md-7 col-lg-8 col-xl-9">
        <div class="row">
          <div class="col-lg-12">
            <div class="card dash-cards">
              <div class="card-body">
                {joined ? (
                  <>
                    <div
                      id="local-stream"
                      className="stream local-stream"
                    ></div>

                    <div
                      id="remote-stream"
                      ref={remoteRef}
                      className="stream remote-stream"
                    ></div>
                  </>
                ) : null}
                  <div className="container">
                  <SignInUpButton
                    name={t("leavecall")}
                    ref={leaveRef}
                    className="booking-card-details-btn starts-in-5h mt-3"
                    onClick={() => handleLeave()}
                    disabled={joined ? "default" : "greyed-out"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ReactModal>
  );
}
