import ReactModal from "react-modal";
import TokenService from "../../services/token.service";
import { useEffect, useState } from "react";
import { CartCircleNumber } from "../CartCircleNumber/CartCircleNumber";
import { useNavigate } from "react-router-dom";
import { Notifications } from "../Notifications/Notifications";
import { useTranslation } from "react-i18next";

export const MobileMenuBar = ({ modalOpen, setModalOpen }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  function handleLanguageChange(lang) {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  }

  const customStyles = {
    content: {
      position: "absolute",
      inset: "0px",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgba(172, 210, 255, 0.9)",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "67%",
      height: "100vh",
      maxWidth: "700px",
      top: "3rem",
    },
    overlay: {
      position: "fixed",
      zIndex: 1020,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };
  return (
    <ReactModal
      isOpen={modalOpen}
      contentLabel="Mobile menu"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="position-relative mt-3">
        <img
          src="./img/close.svg"
          className="close-img"
          onClick={() => {
            setModalOpen(false);
          }}
        />
        <div className="navbarsearch-bar flex-column gap-0">
          <div className="navbar flex-column gap-0">
            <div>
              <a href="/">
                <img
                  className="logo-icon1 mx-3"
                  alt=""
                  src="/img/logo@2x.png"
                />
              </a>
            </div>

            <div className="cta">
              {TokenService.getUser()?.JWTtoken ? (
                <a
                  href={TokenService.getUser()?.confirmed ? "/dashboard" : "/"}
                >
                  <div className="hello-mesg">
                    <span className="hello">{t("header.hello")},</span>
                    <span className="name">{`${
                      TokenService.getUser()?.firstname
                    }!`}</span>
                  </div>
                </a>
              ) : (
                <a href="/login">
                  <div className="navbar-button-sign-up">
                    <div className="clinic">{t("header.siginuplogin")}</div>
                  </div>
                </a>
              )}
            </div>
            <div className="navbar1 flex-column">
              <div
                className={`navbar-button-clinic ${
                  window.location.pathname === "/clinics" ? "active-nav" : ""
                }`}
              >
                <div className="clinic">
                  <a
                    href="/clinics"
                    className={`${
                      window.location.pathname === "/clinics"
                        ? "active-text"
                        : ""
                    }`}
                  >
                    {t("header.clinic")}
                  </a>
                </div>
              </div>
              <div
                className={`navbar-button-clinic ${
                  window.location.pathname === "/tele-health"
                    ? "active-nav"
                    : ""
                }`}
              >
                <div className="clinic">
                  <a
                    href="/tele-health"
                    className={`${
                      window.location.pathname === "/tele-health"
                        ? "active-text"
                        : ""
                    }`}
                  >
                    {t("header.telehealth")}
                  </a>
                </div>
              </div>
              <div
                className={`navbar-button-clinic ${
                  window.location.pathname === "/home-visit" ? "active-nav" : ""
                }`}
              >
                <div className="clinic">
                  <a
                    href="/home-visit"
                    className={`${
                      window.location.pathname === "/home-visit"
                        ? "active-text"
                        : ""
                    }`}
                  >
                    {t("header.homevisit")}
                  </a>
                </div>
              </div>
              <div
                className={`navbar-button-clinic ${
                  window.location.pathname === "/nursing" ||
                  window.location.pathname === "/nursing-details"
                    ? "active-nav"
                    : ""
                }`}
              >
                <div className="clinic">
                  <a
                    href="/nursing"
                    className={`${
                      window.location.pathname === "/nursing" ||
                      window.location.pathname === "/nursing-details"
                        ? "active-text"
                        : ""
                    }`}
                  >
                    {t("header.nursing")}
                  </a>
                </div>
              </div>
              <div
                className={`navbar-button-clinic ${
                  window.location.pathname === "/lab-scan" ||
                  window.location.pathname === "/lab-scan-details"
                    ? "active-nav"
                    : ""
                }`}
              >
                <div className="clinic">
                  <a
                    href="/lab-scan"
                    className={`${
                      window.location.pathname === "/lab-scan" ||
                      window.location.pathname === "/lab-scan-details"
                        ? "active-text"
                        : ""
                    }`}
                  >
                    {t("header.labscans")}
                  </a>
                </div>
              </div>
              <div
                className={`navbar-button-clinic ${
                  window.location.pathname === "/pharmacy" ||
                  window.location.pathname === "/pharmacy-details"
                    ? "active-nav"
                    : ""
                }`}
              >
                <div className="clinic">
                  <a
                    href="/pharmacy"
                    className={`${
                      window.location.pathname === "/pharmacy" ||
                      window.location.pathname === "/pharmacy-details"
                        ? "active-text"
                        : ""
                    }`}
                  >
                    {t("header.pharmacy")}
                  </a>
                </div>
              </div>
            </div>
          </div>
          {TokenService.getUser()?.JWTtoken &&
          TokenService.getUser()?.confirmed &&
          !TokenService.getUser()?.supscription_plan ? (
            <a href="/personal-plans" className="">
              <div className="navbar-button-subscribe">
                <div className="">{t("header.subscribe")}</div>
              </div>
            </a>
          ) : TokenService.getUser()?.JWTtoken &&
            TokenService.getUser()?.confirmed ? (
            <div
              className="d-flex navbar-button-clinic pointer"
              onClick={() =>
                navigate("/dashboard", {
                  state: {
                    selected: "subscription",
                  },
                })
              }
            >
              <img src="./img/premium.svg" />
              <div className="clinic">
                <b className="p-2">{t("subscribed")}</b>
              </div>
            </div>
          ) : (
            ""
          )}
           <div className="services-button">
              <div
                className="clinic"
                onClick={() =>
                  handleLanguageChange(i18n.language === "en" ? "ar" : "en")
                }
              >
                <b>
                {i18n.language === "en" ? "ع" : "EN"}
                </b>
              </div>
            </div>
        </div>
      </div>
    </ReactModal>
  );
};
