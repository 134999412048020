export const getPharmaImage = (name) => {
  if (name !== null) {
    return (
      "https://doxx-images.s3.eu-central-1.amazonaws.com/pharma/items/" +
      name
        .replaceAll("/", "-")
        .replaceAll("<", "(")
        .replaceAll(">", ")") +
      ".jpg"
    );
  }
  return "./img/image-placeholder.png";
};
