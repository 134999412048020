

import React from "react";
import { useTranslation } from "react-i18next";

export const Visibility2 = ({ className, onClick }) => {
  const {i18n} = useTranslation()
  return (
    <svg
      className={`visibility-2 ${className} eye-svg ${i18n.language === 'ar' ? 'eye-svg-right' : 'eye-svg-left'}`}
      fill="none"
      height="25"
      viewBox="0 0 24 25"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      onClick={()=>onClick(true)}
    >
      <g className="g" clipPath="url(#clip0_174_1793)">
        <path
          className="path"
          d="M12 6.99999C14.76 6.99999 17 9.23999 17 12C17 12.51 16.9 13 16.76 13.46L19.82 16.52C21.21 15.29 22.31 13.75 23 11.99C21.27 7.60999 17 4.49999 12 4.49999C10.73 4.49999 9.51 4.69999 8.36 5.06999L10.53 7.23999C11 7.09999 11.49 6.99999 12 6.99999ZM2.71 3.65999C2.32 4.04999 2.32 4.67999 2.71 5.06999L4.68 7.03999C3.06 8.32999 1.77 10.03 1 12C2.73 16.39 7 19.5 12 19.5C13.52 19.5 14.97 19.2 16.31 18.68L19.03 21.4C19.42 21.79 20.05 21.79 20.44 21.4C20.83 21.01 20.83 20.38 20.44 19.99L4.13 3.65999C3.74 3.26999 3.1 3.26999 2.71 3.65999ZM12 17C9.24 17 7 14.76 7 12C7 11.23 7.18 10.5 7.49 9.85999L9.06 11.43C9.03 11.61 9 11.8 9 12C9 13.66 10.34 15 12 15C12.2 15 12.38 14.97 12.57 14.93L14.14 16.5C13.49 16.82 12.77 17 12 17ZM14.97 11.67C14.82 10.27 13.72 9.17999 12.33 9.02999L14.97 11.67Z"
          fill="#7E7E7E"
        />
      </g>
      <defs className="defs">
        <clipPath className="clip-path" id="clip0_174_1793">
          <rect className="rect" fill="white" height="24" transform="translate(0 0.5)" width="24" />
        </clipPath>
      </defs>
    </svg>
  );
};
