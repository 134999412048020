import { t } from "i18next";
import {Helmet} from "react-helmet";

export const MetaTags = ({ title, description, image }) => {  
  return (
    <Helmet>
        <title>{title}</title>
        <meta name="title" content={title} />
        <meta name="description" content={description} />
        <meta name="keywords" content={title} />
        <meta property="og:type" content="website" />
        <meta property="og:url" content={document.URL} />
        <meta property="og:title" content={description} />
        <meta property="og:description" content={description}/>
        <meta property="og:image" content={image} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={document.URL} />
        <meta property="twitter:title" content={t('doxx')} />
        <meta property="twitter:description" content={description}/>
        <meta property="twitter:image" content={image} />
        <link rel="canonical" href={document.URL} />
    </Helmet>
  );
};
