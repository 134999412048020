export const RadioButton = ({
  name,
  type,
  label,
  currentType,
  onChange,
  disabled,
  className,
  labelClassName,
}) => {
  return (
    <div className={`form-check ${className}`}>
      <input
        className="form-check-input"
        type="radio"
        name={name}
        defaultChecked={type === currentType}
        onClick={onChange}
        disabled={disabled}
      />
      <label className={`form-check-label ${labelClassName}`}>{label}</label>
    </div>
  );
};
