import { t } from "i18next";
import { DoctorCard } from "../DoctorCard/DoctorCard";
import "./style.css";
import ReactPaginate from "react-paginate";
import { ConvertToArabicNumbers } from "../../helpers/convertToArabic";

export const DoctorCards = ({
  result,
  limit,
  setOffset,
  offset,
  setModalOpen,
  setItem,
  type,
  doctorKey
}) => {
  return (
    <div className="doctor-cards mb-4" key={doctorKey}>
      {result?.result?.map((item, key) => (
        <DoctorCard
          item={item}
          key={`doctor-card-${key}`}
          setModalOpen={setModalOpen}
          setItem={setItem}
          type={type}
        />
      ))}
      <div className="pagination-container">
        <ReactPaginate
          breakLabel="..."
          nextLabel={t("pagination.next")}
          onPageChange={(e) => {
            setOffset(e.selected + 1);
          }}
          pageRangeDisplayed={limit}
          pageCount={Math.ceil(result?.orginalTotal / limit)}
          previousLabel={t("pagination.prev")}
          renderOnZeroPageCount={null}
          disabledClassName	= "greyed-out"
          pageLabelBuilder={(p)=>ConvertToArabicNumbers(p)}
        />
      </div>
    </div>
  );
};
