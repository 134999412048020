import React from "react";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { FeatureBanner } from "../FeatureBanner/FeatureBanner";
import { useTranslation } from "react-i18next";

export const DashboardBanners = ({}) => {
  const { t } = useTranslation();
  return (
    <Slide slidesToScroll={1} slidesToShow={1} indicators={true} arrows={false}>
      <FeatureBanner
        type="clinic"
        title1={t("clinic.banner.title")}
        title2={t("clinic.banner.title2")}
        desc={t("clinic.banner.desc")}
        imgSrc={"/img/dr-isolated-2@2x.png"}
        dashboardFlag={true}
        urlLink = "/clinics"
      />
      <FeatureBanner
        type="home-visit"
        title1={t("homevisit.banner.title")}
        title2={t("homevisit.banner.title2")}
        desc={t("homevisit.banner.desc")}
        desc2={t("homevisit.banner.desc2")}
        imgSrc={"./img/patient-with-doctor-at-home-cutout 1.png"}
        dashboardFlag={true}
        urlLink = "/home-visit"
      />
      <FeatureBanner
        type="scan"
        color="bg-scan"
        title1={t("scanlab.title")}
        title2={t("scanlab.title2")}
        desc={t("scanlab.desc")}
        desc2={""}
        imgSrc="./img/cutout-1@2x.png"
        dashboardFlag={true}
        urlLink="/lab-scan"
      />
      <FeatureBanner
        type="nursing"
        color="bg-nursing"
        title1={t("scanlab.title")}
        title2={t("scanlab.title2")}
        desc={t("scanlab.desc")}
        desc2={""}
        imgSrc="./img/cutout-1@2x.png"
        dashboardFlag={true}
        urlLink="/nursing-details"
      />
      <FeatureBanner
        type="scan"
        color="bg-scan"
        title1={t("scanlab.title")}
        title2={t("scanlab.title2")}
        desc={t("scanlab.desc")}
        desc2={""}
        imgSrc="./img/woman-holding-blood-sample-microscope-cutout 1.png"
        dashboardFlag={true}
        urlLink="/lab-scan"
      />
      <FeatureBanner
        type="tele-health"
        title1={t("telehealth.banner.title")}
        title2={t("telehealth.banner.title2")}
        desc={t("telehealth.banner.desc")}
        desc2={t("telehealth.banner.desc2")}
        imgSrc={"./img/teleconsultation cutout 1.png"}
        dashboardFlag={true}
        urlLink="/tele-health"
      />
    </Slide>
  );
};
