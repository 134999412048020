import { useTranslation } from "react-i18next";
import { SignInUpButton } from "../SignInUpButton";
import DateService from "../../services/date.service";
import Moment from "moment";
import { ConvertToArabicNumbers } from "../../helpers/convertToArabic";

export const DashboardBookingCard = ({ item, viewDetailsModal }) => {
  const { t, i18n } = useTranslation();

  return (
    <div className="booking-card">
      <div className="specialist-details">
        <div className="nametitlepic">
          <img
            className="image-placeholder-icon"
            alt="booking-img"
            src={
              item?.attributes?.doctor?.data?.attributes?.profile?.data
                ?.attributes?.imageUrl
                ? item?.attributes?.doctor?.data?.attributes?.profile?.data
                    ?.attributes?.imageUrl
                : "./img/person@2x.png"
            }
          />
          <div className="d-flex flex-column">
            <div className="nametitle">
              <b className="dr-hamdy-maged">
                {i18n.language === "ar"
                  ? `د.${item?.attributes?.doctor?.data?.attributes?.profile?.data?.attributes?.firstname_ar} ${item?.attributes?.doctor?.data?.attributes?.profile?.data?.attributes?.lastname_ar}`
                  : `Dr. ${item?.attributes?.doctor?.data?.attributes?.profile?.data?.attributes?.firstname} ${item?.attributes?.doctor?.data?.attributes?.profile?.data?.attributes?.lastname}`}
              </b>
            </div>
            <div className="dermatologist">
             {t(item?.attributes?.appointment?.data?.attributes?.doctor_service?.data?.attributes?.serviceType)}
            </div>
            {/* {item?.attributes?.paymentStatus === "unpaid" &&
            item?.attributes?.paymentType === "pre_paid" &&
            item?.attributes?.appointment?.data?.attributes?.status !==
              "cancelled" ? (
              <SignInUpButton
                name={t("completepayment")}
                className={"pay-btn selected-time-slot"}
                onClick={() => {
                  window.location.href = item?.attributes?.payment_url;
                }}
              />
            ) : (
              ""
            )} */}
          </div>
        </div>
        {item?.attributes?.appointment?.data?.attributes?.status ===
        "cancelled" ? (
          <div className="booking-card-time text-danger">{t("canceled")}</div>
        ) : (
          <div className="booking-card-time">
            <div className="pm-700pm">
              {item?.attributes?.appointment?.data?.attributes?.timeslot?.data
                ?.attributes?.start
                ? `${new Date(
                    item?.attributes?.appointment?.data?.attributes?.timeslot?.data?.attributes?.start
                  )?.toLocaleString(`${i18n.language}-EG`, {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })} - ${new Date(
                    item?.attributes?.appointment?.data?.attributes?.timeslot?.data?.attributes?.end
                  )?.toLocaleString(`${i18n.language}-EG`, {
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                  })}`
                : ""}
            </div>
            {item?.attributes?.type === "appointment" ? (
              <div>
                {DateService.calculateTimeDifference(
                  null,
                  item?.attributes?.appointment?.data?.attributes?.timeslot
                    ?.data?.attributes?.start
                ) === false ? (
                  Moment(
                    item?.attributes?.appointment?.data?.attributes?.timeslot
                      ?.data?.attributes?.start
                  ).format("YYYY/MM/DD")
                ) : (
                  <div className="starts-in">
                    <img
                      className="access-time-icon"
                      alt=""
                      src="./img/access_time.svg"
                    />
                    <div className="starts-in-5h">{
                    DateService.calculateTimeDifference(
                      null,
                      item?.attributes?.appointment?.data?.attributes
                        ?.timeslot?.data?.attributes?.start
                    )?.hours > 0 ?
                    `${t("Starts in")} ${
                      DateService.calculateTimeDifference(
                        null,
                        item?.attributes?.appointment?.data?.attributes
                          ?.timeslot?.data?.attributes?.start
                      ) === "unknown"
                        ? ""
                        : ConvertToArabicNumbers(DateService.calculateTimeDifference(
                            null,
                            item?.attributes?.appointment?.data?.attributes
                              ?.timeslot?.data?.attributes?.start
                          )?.hours)
                    } ${t('h')}` :  `${t("Starts in")}  ${ConvertToArabicNumbers(DateService.calculateTimeDifference(
                      null,
                      item?.attributes?.appointment?.data?.attributes
                        ?.timeslot?.data?.attributes?.start
                    )?.minutes)} ${t("min")}` }</div>
                  </div>
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        )}
        <div className="contact-info height-fit">
          <div className="pm-700pm" dir="ltr">
            {i18n.language === "ar"
              ? ConvertToArabicNumbers(
                  item?.attributes?.doctor?.data?.attributes?.profile?.data
                    ?.attributes?.user?.data?.attributes?.mobileNumber
                )
              : item?.attributes?.doctor?.data?.attributes?.profile?.data
                  ?.attributes?.user?.data?.attributes?.mobileNumber}
          </div>
        </div>
        <SignInUpButton
          name={t("bookingdetails")}
          className="booking-card-details-btn starts-in-5h"
          onClick={() => viewDetailsModal(item)}
        />
      </div>
    </div>
  );
};
