import { t } from "i18next";
import "./style.css";

export const CheckBox = ({ label, onChange, selected, value, privacyPolicy }) => {
  function checkHere(event, label) {
    if (event.currentTarget.checked) {
      onChange(label);
    } else {
      onChange("");
    }
  }
  return (
    <div className="male d-flex">
      <input
        type="checkbox"
        id={value}
        name="male"
        className="mx-2 male checkbox-icon"
        onChange={(e) => checkHere(e, value)}
        checked={selected === value}

      />
      <label htmlFor={value} className="checkbox-label">
        {`${label}`} { privacyPolicy ? <a href="/privacy-policy" target="_blank">{t("privacypolicy")}</a> : ''}
      </label>
    </div>
  );
};
