 import React from "react";

export const NumberInput = ({
  className,
  placeholder,
  onChange,
  error,
  divClass,
  value,
}) => {
  return (
    <div className={`${divClass}`}>
      <input
        type="text"
        className={`email-input ${className}`}
        placeholder={placeholder}
        onChange={(e) => {
          if (/[0-9]/.test(e?.target?.value) || !e?.target?.value) {
            onChange(e?.target?.value);
          }
        }}
        value={value}
        maxLength={6}
      />
      <p className="text-danger  h6 position-absolute  text-start">{error}</p>
    </div>
  );
};
