import { toast } from "react-toastify";
import { editData, getData, postData } from "./apiService";
import TokenService from "./token.service";
import { t } from "i18next";
import i18n from "../i18n";

const book = async (
  methodType,
  promoCode,
  type,
  setPaymentUrl,
  setLoadingFlag,
  setSuccessOrder,
  selectedAddress
) => {
  setLoadingFlag(true);
  const appointment = JSON.parse(localStorage.getItem("appointments"));
  let pharmaCart = localStorage.getItem("pharmacy-cart")
    ? JSON.parse(localStorage.getItem("pharmacy-cart"))
    : [];
  let nursingCart = localStorage.getItem("nurse-cart")
    ? JSON.parse(localStorage.getItem("nurse-cart"))
    : [];
  let labCart = localStorage.getItem("lab-cart")
    ? JSON.parse(localStorage.getItem("lab-cart"))
    : [];
  let scanCart = localStorage.getItem("scan-cart")
    ? JSON.parse(localStorage.getItem("scan-cart"))
    : [];

  const user = TokenService.getUser();
  let items = [];
  let redirect_url = "";
  const url = window.location.href.split("/");

  if (
    scanCart?.length +
      labCart?.length +
      pharmaCart?.length +
      nursingCart?.length ===
    0
  ) {
    redirect_url = `${url[0]}//${url[2]}/dashboard?selected=booking`;
  } else {
    redirect_url = `${url[0]}//${url[2]}/cart`;
  }

  appointment
    .filter((booking_item) => booking_item.type === type)
    .forEach((element) => {
      items.push({
        itemId: element?.selectedService.id,
      });
    });

    let data = {"data": {
      promoCode,
      "redeemedPoints": null,
      "location": null,
      "paymentType": methodType,
      "patient": user.patient_id,
      "type": "appointment",
      "items": [
          {
              "itemId": appointment[0]?.selectedService?.id
          }
      ],
      "prescription": null,
      "doctor": appointment?.length > 0 ? appointment[0].doctorId : null,
      "timeslot": appointment?.length > 0 ? appointment[0].timeslot?.id : null,
      "complaint": "",
      "showMedicalHistory": true,
      "followup": false,
      "web": true,
      redirect_url
     },
     "applicationLanguage": i18n?.language
   };

  await postData("/orders", data , true)
    .then((response) => {
      setSuccessOrder(true);
      if (
        response &&
        response.cashierUrl &&
        response.paymentType === "pre_paid"
      ) {
        localStorage.setItem("order_reference", response?.reference);
        setPaymentUrl(response?.cashierUrl);
      }
    })
    .catch(() => setLoadingFlag(false));
};

const checkout = async (
  methodType,
  promoCode,
  type,
  setPaymentUrl,
  setLoadingFlag,
  setSuccessOrder,
  selectedAddress,
  setLabCart,
  setNursingCart,
  setScanCart,
  setPharmaCart,
  serviceType
) => {
  setLoadingFlag(true);
  const items = JSON.parse(localStorage.getItem(`${type}-cart`));
  const user = TokenService.getUser();
  let submittedItems = [];
  let redirect_url = "";
  const url = window.location.href.split("/");

  items.forEach((element) => {
    submittedItems.push({
      itemId: element?.id,
      quantity: element?.quantity,
    });
  });

  let pharmaCart = localStorage.getItem("pharmacy-cart")
    ? JSON.parse(localStorage.getItem("pharmacy-cart"))
    : [];
  let nursingCart = localStorage.getItem("nurse-cart")
    ? JSON.parse(localStorage.getItem("nurse-cart"))
    : [];
  let labCart = localStorage.getItem("lab-cart")
    ? JSON.parse(localStorage.getItem("lab-cart"))
    : [];
  let scanCart = localStorage.getItem("scan-cart")
    ? JSON.parse(localStorage.getItem("scan-cart"))
    : [];

  if (
    scanCart?.length +
      labCart?.length +
      pharmaCart?.length +
      nursingCart?.length -
      items?.length ===
    0
  ) {
    redirect_url = `${url[0]}//${url[2]}/dashboard?selected=order`;
  } else {
    redirect_url = `${url[0]}//${url[2]}/cart`;
  }

  const data = {
    patient: user?.patient_id,
    paymentType: methodType,
    type,
    items: submittedItems,
    promoCode,
    showMedicalHistory: true,
    followup: false,
    prescription: null,
    location: type === "lab" || type === "scan" ? serviceType : null,
    redeemedPoints: null,
    web: true,
    address: selectedAddress,
    redirect_url,
  };
  await postData("/orders", { data }, true)
    .then((response) => {
      setSuccessOrder(true);
      if (
        response &&
        response.cashierUrl &&
        response.paymentType === "pre_paid"
      ) {
        localStorage.setItem("order_reference", response?.reference);
        setPaymentUrl(response?.cashierUrl);
      }
      switch (type) {
        case "lab":
          setLabCart([]);
          break;
        case "scan":
          setScanCart([]);
          break;
        case "nurse":
          setNursingCart([]);
          break;
        case "pharmacy":
          setPharmaCart([]);
          break;
        default:
          break;
      }
      localStorage.setItem(`${type}-cart`, []);
    })
    .catch(() => setLoadingFlag(false));
};

const getDoctorAvailability = async (
  doctorId,
  setClinics,
  setHomeVisit,
  setTelehealth,
  type,
  setLoadingFlag
) => {
  setLoadingFlag(true);
  const params = {
    "populate[clinic_service][populate]": "clinic.address",
    "populate[homevisit_service][populate]": "cities",
    "filters[doctor][id][$eq]": doctorId,
    "populate[doctor]": "*",
    "populate[working_days][populate][timeslots][filters][start][$gt]":
      new Date(),
    locale: localStorage.getItem("lang"),
  };
  await getData("/doctor-services", params)
    .then((response) => {
      const data = response.data;
      const clinics = data.filter(
        (item) => item.attributes?.serviceType === "clinic"
      );
      const homeVisits = data.filter(
        (item) => item.attributes?.serviceType === "homevisit"
      );
      const teleHealths = data.filter(
        (item) => item.attributes?.serviceType === "telehealth"
      );
      setClinics(clinics);
      setHomeVisit(homeVisits);
      setTelehealth(teleHealths);

      setLoadingFlag(false);
    })
    .catch(() => setLoadingFlag(false));
};

const getServiceAvailability = async (
  serviceId,
  setSelectedService,
  selectService,
  setLoadingFlag
) => {
  setLoadingFlag(true);
  const params = {
    "populate[clinic_service][populate]": "clinic.address",
    "populate[homevisit_service][populate]": "cities",
    "populate[working_days][populate][timeslots][filters][start][$gt]":
      new Date(),
    locale: localStorage.getItem("lang"),
  };
  await getData(`/doctor-services/${serviceId}`, params)
    .then((response) => {
      setSelectedService(response?.data);
      selectService(response?.data);
      setLoadingFlag(false);
    })
    .catch(() => setLoadingFlag(false));
};

const calculatePrice = async (
  setLoadingFlag,
  type,
  setTotalCalculation,
  promoCode
) => {
  setLoadingFlag(true);
  const appointment = JSON.parse(localStorage.getItem("appointments"));
  const user = TokenService.getUser();
  let items = [];
  appointment
    .filter((booking_item) => booking_item.type === type)
    .forEach((element) => {
      items.push({
        itemId: element?.selectedService.id,
        quantity: element?.quantity ? element?.quantity : 1,
      });
    });

  const data = {
    data: {
      patient: user?.patient_id,
      type:
        type === "clinic"
          ? "walkin"
          : type === "home-visit"
          ? "mobicare"
          : type === "tele-health"
          ? "telehealth"
          : "",
      line_items: items,
      promo: promoCode,
    },
  };

  await postData("/getTotal", data, true)
    .then((response) => {
      setTotalCalculation(response?.data);
      setLoadingFlag(false);
      if (response?.data?.promo?.data?.valid === false) {
        toast.error(t("invalid.promo"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return true;
    })
    .catch(() => {
      toast.error(t("SomethingWrongErr"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setLoadingFlag(false);
    });
};

const calculateCartPrice = async (
  setLoadingFlag,
  type,
  setTotalCalculation,
  promoCode
) => {
  setLoadingFlag(true);
  const cart = JSON.parse(localStorage.getItem(`${type}-cart`));
  const user = TokenService.getUser();
  let items = [];
  cart?.forEach((element) => {
    items.push({
      itemId: element?.id,
      quantity: element?.quantity ? element?.quantity : 1,
    });
  });

  const data = {
    data: {
      patient: user?.patient_id,
      promo: promoCode,
      type,
      line_items: items,
    },
  };

  await postData("/getTotal", data, true)
    .then((response) => {
      setTotalCalculation(response?.data);
      setLoadingFlag(false);
      if (response?.data?.promo?.data?.valid === false) {
        toast.error(t("invalid.promo"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      return true;
    })
    .catch(() => {
      toast.error(t("SomethingWrongErr"), {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      setLoadingFlag(false);
    });
};

const rescheduleAppointment = async (id, newSlotId, updateAppointment) => {
  editData("/orders", id, {
    data: { status: "rescheduled", timeslot: newSlotId },
  })
    .then((response) => {
      updateAppointment(response);
    })
    .catch((err) => {
      throw err;
    });
};

const checkPayment = async (reference) => {
  postData("/check_payment", { reference }, true)
    .then(() => {
      localStorage.removeItem("order_reference");
    })
    .catch((err) => {
      throw err;
    });
};

const BookingService = {
  book,
  getDoctorAvailability,
  calculatePrice,
  calculateCartPrice,
  checkout,
  rescheduleAppointment,
  getServiceAvailability,
  checkPayment,
};

export default BookingService;
