import { Tooltip as ReactTooltip } from "react-tooltip";

export const TooltipPlace = ({ id, place, content }) => {
  return (
    <ReactTooltip
      id={id}
      place={place}
      content={content}
    />
  );
};
