import { useTranslation } from "react-i18next";
import { formatter } from "../../helpers/currencyFormatter";
import { SignInUpButton } from "../SignInUpButton";
import Moment from "moment";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { ConvertToArabicNumbers } from "../../helpers/convertToArabic";

export const DashboardOrderCard = ({ item, viewDetailsModal }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="booking-card">
      <div className="specialist-details">
        <div className="nametitlepic">
          <div className="d-flex flex-column">
            <div className="nametitle">
              <b className="dr-hamdy-maged">{`${t("order")}: ${
                i18n.language === "ar"
                  ? ConvertToArabicNumbers(item?.id)
                  : item?.id
              }`}</b>
            </div>
          </div>
        </div>
        <div className="contact-info align-items-start d-flex flex-column">
          <div className="justify-content-start d-flex gap-2">
            <b>{`${t("orderdate")}: `}</b>
            <div>
              {Moment(item?.attributes?.createdAt).format("YYYY/MM/DD")}
            </div>
          </div>
          <div className="justify-content-start d-flex gap-2">
            <b>{`${t("type")}: `}</b>
            <div>{` ${t(
              item?.attributes?.type === "lab"
                ? t("analysis")
                : item?.attributes?.type === "scan"
                ? t("scan")
                : item?.attributes?.type === "pharmacy"
                ? t("header.pharmacy")
                : item?.attributes?.type === "nurse"
                ? t("header.nursing")
                : ""
            )}`}</div>
          </div>
          <div className="justify-content-start d-flex gap-2">
            <b>{`${t("status")}: `}</b>
            <div>
              {item?.attributes?.type === "nurse"
                ? t(item?.attributes?.nurse_order?.data?.attributes?.status)
                : ""}
              {item?.attributes?.type === "pharmacy"
                ? t(item?.attributes?.pharmacy_order?.data?.attributes?.status)
                : ""}
              {item?.attributes?.type === "lab"
                ? t(item?.attributes?.lab_order?.data?.attributes?.status)
                : ""}
              {item?.attributes?.type === "scan"
                ? t(item?.attributes?.scan_order?.data?.attributes?.status)
                : ""}
            </div>
          </div>

          {item?.attributes?.type === "lab" ? (
            <div className="justify-content-start d-flex gap-2">
              <b>{`${t("location")}: `}</b>
              <div>
                {item?.attributes?.lab_order?.data?.attributes?.location ===
                "lab"
                  ? t("atlab")
                  : t("athome")}
              </div>
            </div>
          ) : (
            ""
          )}
          {item?.attributes?.type === "scan" ? (
            <div lassName="justify-content-start d-flex gap-2">
              <b>{`${t("location")}: `}</b>
              {item?.attributes?.scan_order?.data?.attributes?.location ===
              "lab"
                ? t("atlab")
                : t("athome")}
            </div>
          ) : (
            ""
          )}
          <div className="justify-content-start d-flex gap-2">
            <b>{`${t("total")}: `}</b>
            <div>
              <div>{` ${formatter().format(
                item?.attributes?.totalPrice
              )}`}</div>
            </div>
          </div>
          {/* {item?.attributes?.paymentStatus === "unpaid" &&
          item?.attributes?.paymentType === "pre_paid" ? (
            <SignInUpButton
              name={t("completepayment")}
              className={"pay-btn selected-time-slot"}
              onClick={() => {
                window.location.href = item?.attributes?.payment_url;
              }}
            />
          ) : (
            ""
          )} */}
        </div>
        <SignInUpButton
          name={t("orderdetails")}
          className="booking-card-details-btn starts-in-5h"
          onClick={() => viewDetailsModal(item)}
        />
      </div>
    </div>
  );
};
