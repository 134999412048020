import React, { useState } from "react";
import { DoctorStar } from "../../components/DoctorStar";
import { PatientStar } from "../../components/PatientStar";
import "./style.css";
import { SignInFlow } from "../SignInFlow";
import { useTranslation } from "react-i18next";

export const RegistrationFlow = ({ setLoadingFlag }) => {
  const [type, setType] = useState(null);
  const { t } = useTranslation();

  return type ? (
    <SignInFlow setLoadingFlag={setLoadingFlag} type={type} regFlag={true} />
  ) : (
    <div className="registration-flow">
      <div className="div d-flex flex-column">
        <div className="d-flex welcome-div ">
          <a href="/">
            <img className="logo" alt="Logo" src="/img/logo2.svg" />
          </a>
          <div className="d-flex flex-column m-auto mt-5 ">
            <div className="text-wrapper">{t("auth.welcometitle")}</div>
            <div className="text-wrapper-2">{t("auth.areyou")}</div>
          </div>
        </div>
        <div className="overlap-group position-relative d-flex">
          <DoctorStar
            className="doctor-star-instance"
            property1="frame-12"
            starStarClassName="design-component-instance-node"
            onClick={() => {
              window.location.pathname ='/doctor-registration'
            }}
            title={t("auth.doctor")}
          />
          <PatientStar
            className="patient-star-instance"
            starStar="/img/star-1-1.svg"
            starStarClassName="patient-star-2"
            stateProp="default"
            onClick={() => {
              setType("patient");
            }}
            title={t("auth.patient")}
          />
          <img
            className="doctor-health z-1"
            alt="Doctor health"
            src="/img/doctor-health-illustration-3-1.svg"
          />
        </div>
      </div>
    </div>
  );
};
