const bloodTypes = [
   {
    name: "A+"
   },
   {
    name: "A-"
   },
   {
    name: "B+"
   },
   {
    name: "B-"
   },
   {
    name: "O+"
   },
   {
    name: "O-"
   },
   {
    name: "AB+"
   },
   {
    name: "AB-"
   }
];
export default bloodTypes;