import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import "../BookingModal/style.css";
import "react-calendar/dist/Calendar.css";
import { useState } from "react";
import { SignInUpButton } from "../SignInUpButton/SignInUpButton";
import TokenService from "../../services/token.service";
import { editData } from "../../services/apiService";
import { toast } from "react-toastify";

export const CancelOrderModal = ({
  id,
  type,
  modalOpen,
  setModalOpen,
  setLoadingFlag,
}) => {
  const { t, i18n } = useTranslation();
  const [cancelReason, setCancelReason] = useState("");

  const cancelOrder = async () => {
    setLoadingFlag(true);
    const url = window.location.href.split("/");
    const data = {
      data: {
        status: "cancelled",
        cancelReason,
      },
    };

    editData("/orders", id, data)
      .then(() => {
        if (type === "appointment") {
          window.location.href = `${url[0]}//${url[2]}/dashboard?selected=booking`;
        } else {
          window.location.href = `${url[0]}//${url[2]}/dashboard?selected=order`;
        }
      })
      .catch(() => {
        toast.error(t("cant.cancel.order"), {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      });
  };

  const customStyles = {
    content: {
      position: "absolute",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(238, 238, 238)",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "67%",
      height: "fit-content",
      maxWidth: "700px",
      top: "3rem",
      margin: "auto",
    },
    overlay: {
      position: "fixed",
      zIndex: 1020,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };
  return (
    <ReactModal
      isOpen={modalOpen}
      contentLabel="Medical Question"
      style={customStyles}
      ariaHideApp={false}
    >
      <div className="position-relative mt-3">
        <img
          src="./img/close.svg"
          className="close-img"
          onClick={() => {
            setModalOpen(false);
          }}
        />
        <div className="form-group">
          <textarea
            className="form-control question-textarea"
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
            id="exampleFormControlTextarea1"
            rows="3"
            placeholder={t("enter.cancel.reason")}
            onChange={(e) => setCancelReason(e.target.value)}
            defaultValue={cancelReason}
          ></textarea>
        </div>
      </div>
      <SignInUpButton
        state={cancelReason === "" ? "greyed-out" : "default"}
        className={"mt-3 book-btn-modal btn btn-danger"}
        name={t("cancel.order")}
        onClick={() => cancelOrder()}
      />
    </ReactModal>
  );
};
