import { t } from "i18next";
import "./style.css";
import { ConvertToArabicNumbers } from "../../helpers/convertToArabic";
import { MetaTags } from "../../components/MetaTags/MetaTags";

const PrivacyPolicy = ({ setLoadingFlag }) => {
  return (
    <>
      <MetaTags
        title={`${t("doxx")} - ${t("privacypolicy")}`}
        description={`${t("privacypolicy.agreement")}`}
      />
      <div class="content mt-4">
        <div class="m-auto width90per">
          <h2>{t("privacypolicy")}</h2>
          <p className="privacy-li mb-1">
            <b>{t("privacyFirstParagraph")}:-</b>
          </p>
          <p></p>
          <p className="privacy-li mb-1">
            <b>{t("informationCollection")}:-</b>
          </p>
          <ul>
            <li className="privacy-li">{t("informationCollectionContent")}</li>
          </ul>
          <p className="privacy-li mb-1">
            <b>{t("dataUsage")}:-</b>
          </p>
          <p className="privacy-li"> {` ${t("dataUsageContent")}`}</p>
          <p className="privacy-li mb-1">
            <b>{t("dataProtection")}:-</b>
          </p>
          <p className="privacy-li"> {` ${t("dataProtectionContent")}`}</p>
          <p className="privacy-li mb-1">
            <b>{t("dataDeletion")}:-</b>
          </p>
          <p className="privacy-li"> {` ${t("dataDeletionContent")}`}</p>
          <p className="privacy-li mb-1">
            <b>{t("additionalClauses")}:-</b>
          </p>
          <p className="privacy-li"> {` ${t("privacypolicy.1")}`}</p>
          <p className="privacy-li"> {` ${t("privacypolicy.2")}`}</p>
          <p className="privacy-li"> {` ${t("privacypolicy.3")}`}</p>
          <p className="privacy-li"> {` ${t("privacypolicy.4")}`}</p>
          <p className="privacy-li"> {` ${t("privacypolicy.5")}`}</p>
          <p className="privacy-li mb-1">
            <b>{t("contactUs")}:-</b>
          </p>
          <p className="privacy-li mb-1">
            {t("contactUsContent")}
          </p>

          <p className="privacy-li my-3">
            <b>* {t("privacyLastParagraph")}</b>
          </p>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
