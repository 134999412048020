import AuthService from '../services/auth.service';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Logout = () => {
    const navigate = useNavigate();
    useEffect(()=>{
        AuthService.logout();
        navigate('/');
    })
}

export default Logout;