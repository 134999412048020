import { useTranslation } from "react-i18next";
import "./style.css";
import TokenService from "../../services/token.service";
import { useEffect, useState } from "react";
import { CartCircleNumber } from "../CartCircleNumber/CartCircleNumber";
import { useNavigate } from "react-router-dom";
import { Notifications } from "../Notifications/Notifications";
import { MobileMenuBar } from "../MobileMenuBar/MobileMenuBar";
import { getData } from "../../services/apiService";

export const Header = ({ display, setLoadingFlag }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [notificationDisplay, setNotificationDisplay] = useState("d-none");
  const [mobileModalOpen, setMobileModalOpen] = useState(false);
  const [savedValue, setSavedValue] = useState(0);

  function notificationClick() {
    if (notificationDisplay === "d-none") {
      setNotificationDisplay("d-flex");
    } else {
      setNotificationDisplay("d-none");
    }
  }

  function calculateSavings() {
    getData("/patient/youSaved", null, true).then((res) => {
      setSavedValue(res?.youSaved);
    });
  }

  function handleLanguageChange(lang) {
    i18n.changeLanguage(lang);
    localStorage.setItem("lang", lang);
  }

  useEffect(() => {
    if (TokenService?.getUser()) {
      calculateSavings();
    }

    window.scrollTo({ top: "1000px" });
  }, [TokenService?.getUser()]);

  return (
    <div className={display}>
      <MobileMenuBar
        modalOpen={mobileModalOpen}
        setModalOpen={setMobileModalOpen}
      />
      <div className="navbarsearch-bar">
        <img
          src="./img/icons8-menu.svg"
          alt="menu-icon"
          className="menu-icon none-web-view"
          onClick={() => setMobileModalOpen(true)}
        />
        <div className="navbar">
          <a href="/">
            <img className="logo-icon1 mx-3" alt="" src="/img/logo@2x.png" />
          </a>
          <div className="navbar1 none-mobile-view">
            <div
              className={`navbar-button-clinic ${
                window.location.pathname === "/clinics"
                  ? "active-nav"
                  : "non-active-navbtn"
              }`}
            >
              <div className="clinic">
                <a
                  href="/clinics"
                  className={`${
                    window.location.pathname === "/clinics"
                      ? "active-text"
                      : "non-active-navbtn"
                  }`}
                >
                  {t("header.clinic")}
                </a>
              </div>
            </div>
            <div
              className={`navbar-button-clinic ${
                window.location.pathname === "/tele-health"
                  ? "active-nav"
                  : "non-active-navbtn"
              }`}
            >
              <div className="clinic">
                <a
                  href="/tele-health"
                  className={`${
                    window.location.pathname === "/tele-health"
                      ? "active-text"
                      : ""
                  }`}
                >
                  {t("header.telehealth")}
                </a>
              </div>
            </div>
            <div
              className={`navbar-button-clinic ${
                window.location.pathname === "/home-visit"
                  ? "active-nav"
                  : "non-active-navbtn"
              }`}
            >
              <div className="clinic">
                <a
                  href="/home-visit"
                  className={`${
                    window.location.pathname === "/home-visit"
                      ? "active-text"
                      : ""
                  }`}
                >
                  {t("header.homevisit")}
                </a>
              </div>
            </div>
            <div
              className={`navbar-button-clinic ${
                window.location.pathname === "/nursing" ||
                window.location.pathname === "/nursing-details"
                  ? "active-nav"
                  : "non-active-navbtn"
              }`}
            >
              <div className="clinic">
                <a
                  href="/nursing-details"
                  className={`${
                    window.location.pathname === "/nursing" ||
                    window.location.pathname === "/nursing-details"
                      ? "active-text"
                      : "non-active-navbtn"
                  }`}
                >
                  {t("header.nursing")}
                </a>
              </div>
            </div>
            <div
              className={`navbar-button-clinic ${
                window.location.pathname === "/lab-scan" ||
                window.location.pathname === "/lab-scan-details"
                  ? "active-nav"
                  : "non-active-navbtn"
              }`}
            >
              <div className="clinic">
                <a
                  href="/lab-scan"
                  className={`${
                    window.location.pathname === "/lab-scan" ||
                    window.location.pathname === "/lab-scan-details"
                      ? "active-text"
                      : "non-active-navbtn"
                  }`}
                >
                  {t("header.labscans")}
                </a>
              </div>
            </div>
            <div
              className={`navbar-button-clinic ${
                window.location.pathname === "/pharmacy" ||
                window.location.pathname === "/pharmacy-details"
                  ? "active-nav"
                  : "non-active-navbtn"
              }`}
            >
              <div className="clinic">
                <a
                  href="/pharmacy"
                  className={`${
                    window.location.pathname === "/pharmacy" ||
                    window.location.pathname === "/pharmacy-details"
                      ? "active-text"
                      : "non-active-navbtn"
                  }`}
                >
                  {t("header.pharmacy")}
                </a>
              </div>
            </div>
          </div>
          <div className="cta none-mobile-view">
            {TokenService.getUser()?.JWTtoken ? (
              <a href={TokenService.getUser()?.confirmed ? "/dashboard" : "/"}>
                <div className="hello-mesg">
                  <span className="hello">{t("header.hello")},</span>
                  <span className="name">{`${
                    TokenService.getUser()?.firstname
                  }!`}</span>
                </div>
              </a>
            ) : (
              <a href="/login">
                <div className="navbar-button-sign-up">
                  <div className="clinic">{t("header.siginuplogin")}</div>
                </div>
              </a>
            )}
            {TokenService.getUser()?.JWTtoken &&
            TokenService.getUser()?.confirmed &&
            !TokenService.getUser()?.supscription_plan ? (
              <a href="/personal-plans" className="none-mobile-view">
                <div className="navbar-button-subscribe">
                  <div className="">{t("header.subscribe")}</div>
                </div>
              </a>
            ) : TokenService.getUser()?.JWTtoken &&
              TokenService.getUser()?.confirmed ? (
              <div
                className="d-flex navbar-button-clinic pointer none-mobile-view"
                onClick={() =>
                  navigate("/dashboard", {
                    state: {
                      selected: "subscription",
                    },
                  })
                }
              >
                <img src="./img/premium.svg" />
                <div className="clinic">
                  <b className="p-2">{t("subscribed")}</b>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <a href="/cart" className="position-relative">
            <img
              className="icon-facebook cart-icon"
              alt=""
              src="/img/shopping-cart@2x.png"
            />
            <CartCircleNumber />
          </a>
          {TokenService?.getUser() ? (
            <div className="d-flex">
              <img src="/img/pngwing.png" className="cart-icon savingsIcon" />
              <div className="d-flex flex-column">
                <span>
                  {savedValue} {t("egp")}
                </span>
                <div>
                  <b>{t("savings")}</b>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className=" none-mobile-view margin1per">
            <div
              className="clinic"
              onClick={() =>
                handleLanguageChange(i18n.language === "en" ? "ar" : "en")
              }
            >
              <b>{i18n.language === "en" ? "ع" : "EN"}</b>
            </div>
          </div>
        </div>
        {TokenService.getUser()?.JWTtoken && (
          <div className="position-relative">
            <img
              className="notifications-icon pointer"
              alt=""
              src="./img/notifications@2x.png"
              onClick={() => notificationClick()}
            />
            <Notifications
              display={notificationDisplay}
              setLoadingFlag={setLoadingFlag}
            />
          </div>
        )}
      </div>
      {/* <div className="search-container">
        <div className="search-bar">
          <img className="search-icon" alt="" src="/img/search@2x.png" />
          <div className="search3">{t("header.search")}</div>
        </div>
      </div> */}
    </div>
  );
};
