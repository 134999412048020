import { useNavigate } from "react-router-dom";
import "./style.css";
import { useTranslation } from "react-i18next";
import { SubscriptionHeader } from "../../components/SubscriptionHeader/SubscriptionHeader";
import { PlanCard } from "../../components/PlanCard/PlanCard";
import { useCallback, useEffect, useState } from "react";
import { getData, postData } from "../../services/apiService";
import { SuccessMessage } from "../../components/SuccessMessage/SuccessMessage";
import { MetaTags } from "../../components/MetaTags/MetaTags";

const PersonalSubscription = ({ setLoadingFlag }) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [plans, setPlans] = useState([]);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [redirectURL, setRedirectURL] = useState();

  const getPlans = useCallback(async () => {
    setLoadingFlag(true);
    const params = {
      "filters[type][$notContains]": "family",
      populate: "*",
    };
    await getData("/supscription-plans", params, true)
      .then((response) => {
        setLoadingFlag(false);
        setPlans(response.data);
      })
      .catch((error) => {
        setLoadingFlag(false);
      });
  }, [setLoadingFlag, setPlans]);

  useEffect(() => {
    getPlans();
  }, [getPlans]);

  async function subscribe(id) {
    setLoadingFlag(true);
    const url = window.location.href.split("/");
    const redirect_url = `${url[0]}//${url[2]}/dashboard?selected=subscription`;
    let body = {
      subscription_id: id,
      web: true,
      redirect_url,
    };
    await postData("/supscription-plan/createSubscription", body, true).then(
      async (response) => {
        setLoadingFlag(false);
        setIsSubscribed(true);
        setRedirectURL(response?.cashierUrl);
      }
    );
  }

  return (
    <>
    <MetaTags title={`${t("doxx")} - ${t("subscription.personalplans")}`} description={`${t("subscription.personalplans")}, ${t(
          "subscription.pricingdescription"
        )}`}/>
      {isSubscribed ? (
        <SuccessMessage
          message={
            redirectURL
              ? t("order.redirectsuccessmessage")
              : t("order.successmessage")
          }
          setLoadingFlag={setLoadingFlag}
          redirectURL={redirectURL}
        />
      ) : (
        <div className="subscription-flow-plus-moveme">
          <SubscriptionHeader
            navTitle={t("subscription.familyplans")}
            navUrl="/family-plans"
            icon="./img/family-restroom@2x-personal.png"
            type="personal"
          />
          <div className="d-flex flex-wrap fit-content gap-3 mx-auto mt-3">
            {plans.map((plan, key) =>
              plan?.attributes?.visible ? (
                <PlanCard
                  plan={plan}
                  key={`${plan?.id}-plan-${key}`}
                  subscribe={() => subscribe(plan?.id)}
                  type="personal"
                />
              ) : (
                ""
              )
            )}
          </div>
          <div
            className={`return-home-button pointer m${
              i18n.language === "ar" ? "s" : "e"
            }-auto`}
            onClick={() => navigate("/")}
          >
            <img
              className="arrow-back-icon"
              alt=""
              src="./img/arrow-back@2x.png"
            />
            <div className="return-home">{t("dashboard.returnhome")}</div>
          </div>
        </div>
      )}
    </>
  );
};

export default PersonalSubscription;
