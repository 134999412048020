import { useTranslation } from "react-i18next";
import { addToCart } from "../../helpers/addToCart";
import { checkObjExist } from "../../helpers/checkObjExist";
import { AddOrMinusCart } from "../AddOrMinusCart/AddOrMinusCart";
import { useEffect, useState } from "react";
import { formatter } from "../../helpers/currencyFormatter";

export const LabCard = ({ name, price, name_ar, id, type }) => {
  const { t, i18n } = useTranslation();
  const [item, setItem] = useState();

  useEffect(() => {
    const listenStorageChange = () => {
      setItem(
        checkObjExist(
          id,
          localStorage.getItem(`${type}-cart`) ? JSON.parse(localStorage.getItem(`${type}-cart`)) : [], type
        )
      );
    };
    listenStorageChange();

    window.addEventListener("storage", listenStorageChange);
    return () => window.removeEventListener("storage", listenStorageChange);
  }, []);

  return (
    <div className="doctor-card height-auto">
      <div className="doctor-name-placeholder">
        <div className="doctor-name-placeholder1">
          {i18n.language === "ar" && name_ar ? name_ar : name}
        </div>
      </div>
      <div className="">
        <div className="costlocation width100per">
          <div className="price-placeholder">
            <div className="cost">{t("doctor.cost")}</div>
            <div className="egp">{`${formatter(i18n?.language).format(price)}`}</div>
          </div>
        </div>
      </div>
      <>
        {checkObjExist(
          id,
          localStorage.getItem(`${type}-cart`) ? JSON.parse(localStorage.getItem(`${type}-cart`)) : [], type
        ) ? (
          <div className="width100per">
            <AddOrMinusCart className="mx-auto add-to-cart-lab" item={item} type={type} />
          </div>
        ) : (
          <div
            onClick={() => addToCart(name, price, name_ar, id, type)}
            className="width100per"
          >
            <div className="book-button mt-4">
              <div className="book">{t("request")}</div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};
