import "./style.css";
import { useEffect, useState } from "react";

export const CartCircleNumber = ({}) => {
    const [totalItems, setTotalItems] = useState(0);
    useEffect(()=>{
        const listenStorageChange = () => {
            let pharmaCart = localStorage.getItem("pharmacy-cart")
              ? JSON.parse(localStorage.getItem("pharmacy-cart"))
              : [];
            let nursingCart = localStorage.getItem("nurse-cart")
              ? JSON.parse(localStorage.getItem("nurse-cart"))
              : [];
            let labCart = localStorage.getItem("lab-cart")
              ? JSON.parse(localStorage.getItem("lab-cart"))
              : [];
            let scanCart = localStorage.getItem("scan-cart")
              ? JSON.parse(localStorage.getItem("scan-cart"))
              : [];
            setTotalItems(pharmaCart?.length + nursingCart?.length + labCart?.length + scanCart?.length);
            
          };

          listenStorageChange();
          window.addEventListener("storage", listenStorageChange);
          return () => window.removeEventListener("storage", listenStorageChange);
    })
  return (
    <div className={`${totalItems > 0 ? 'cart-circle-number' : 'd-none'}`}>
        <div>{totalItems}</div>
    </div>
  );
};
