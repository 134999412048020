import { useCallback, useEffect, useState } from "react";
import { AddAddress } from "../AddAddress/AddAddress";
import { deleteData, getData } from "../../services/apiService";
import { useTranslation } from "react-i18next";

export const PatientAddresses = ({
  addresses,
  setLoadingFlag,
  addAddress,
  deleteAddress,
}) => {
  const [OpenAddingModal, setOpenAddingModal] = useState(false);
  const [regions, setRegions] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [index, setIndex] = useState();
  const { t, i18n } = useTranslation();

  const getRegions = useCallback(async () => {
    await getData("/cities", {
      locale: localStorage.getItem("lang"),
    })
      .then((response) => {
        setRegions(response.data);
      })
      .catch((error) => {
        setLoadingFlag(false);
      });
  }, [setLoadingFlag, setRegions]);


  function openEditAddress(item, i){
    setSelectedItem({});
    setIndex(i);
    setSelectedItem(item);
    setOpenAddingModal(true);
  }

  useEffect(() => {
    getRegions();
  }, []);



  

  return (
    <div className="d-flex width90per flex-wrap m-auto">
      <AddAddress
        modalOpen={OpenAddingModal}
        setModalOpen={setOpenAddingModal}
        cities={regions}
        setLoadingFlag={setLoadingFlag}
        AddAddressToArray={addAddress}
        item = {selectedItem}
        index={index}
      />
      <div
        className={`new-question pointer add-address-btn ${
          i18n?.language === "ar" ? "right88per" : "left88per"
        }`}
      >
        <img
          className="add-circle-outline-icon"
          alt="add-circle"
          src="./img/add-circle-outline@2x.png"
          onClick={() => {setSelectedItem({});setOpenAddingModal(true)}}
        />
      </div>
      {addresses.map((item, key) => (
        <div className="question-box width90per" key={`address-box-${key}`}>
          <div className="input-field-dashboard">
            <div className="even-though-i">{item?.street}</div>
          </div>
          <div></div>
          <div className="actionsdate align-items-start flex-column">
            <div className="justify-content-start d-flex gap-2">
              <b>{item?.defaultAddress ? t("default.address") : ""}</b>
            </div>
            {item?.cities ? (
              <div className="justify-content-start d-flex gap-2">
                <b>{t("address.city")}:</b>
                {item?.cities
                  ?.filter((city) => city.locale === i18n.language)
                  .map((city, cityKey) => (
                    <div key={`${key}-${cityKey}-city-address`}>
                      {`${city.name} ${city?.state ? (',' + city?.state?.name) : ''}`}
                    </div>
                  ))}
              </div>
            ) : (
              ""
            )}
            <div className="justify-content-start  d-flex gap-2">
              <b>{t("tag")} :</b>
              <div>{item?.tag ? item?.tag : '-'}</div>
            </div>
            <div className="justify-content-start d-flex gap-2">
              <b>{t("zipcode")} :</b>
              <div>{item?.zipCode ? item?.zipCode : '-'}</div>
            </div>
            <div className="justify-content-start d-flex gap-2">
              <b>{t("notes")} :</b>
              <div>{item?.note ? item?.note : '-'}</div>
            </div>
            <div
              className={`actions m${i18n?.language === "ar" ? "e" : "s"}-auto`}
            >
              <div className="deledit">
                <div
                  className="edit-icon pointer"
                  onClick={() => {
                    openEditAddress(item, key);
                  }}
                >
                  <img
                    className="edit-icon"
                    alt=""
                    src="./img/edit@2x.png"
                  />
                  <div className="hi-john-doe">{t("edit")}</div>
                </div>
                <div
                  className="delete pointer"
                  onClick={() => {
                    deleteAddress(item);
                  }}
                >
                  <img
                    className="delete-icon"
                    alt=""
                    src="./img/delete@2x.png"
                  />
                  <div className="hi-john-doe">{t("delete")}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
