import { toast } from "react-toastify";
import { addOrUpdate } from "./addOrUpdate";
import { t } from "i18next";

export const addToCart = async (name, price, name_ar, id, type) => {
  const data = {
    id,
    quantity: 1,
    name,
    name_ar,
    price,
  };
  let cartArr = localStorage.getItem(`${type}-cart`)
    ? JSON.parse(localStorage.getItem(`${type}-cart`))
    : [];
  let updatedArray = addOrUpdate(data, cartArr);
  toast(t("itemAddedToCart"));
  localStorage.setItem(`${type}-cart`, JSON.stringify(updatedArray));
  window.dispatchEvent(new Event("storage"));
};
