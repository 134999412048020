import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import "../BookingModal/style.css";
import "react-calendar/dist/Calendar.css";
import { useEffect, useRef, useState } from "react";
import { SignInUpButton } from "../SignInUpButton/SignInUpButton";
import { editData, postData } from "../../services/apiService";
import { TextInput } from "../TextInput";
import { CheckBox } from "../CheckBox/CheckBox";
import { SelectBox } from "../SelectBox/SelectBox";
import TokenService from "../../services/token.service";
import Map from "../Map/Map";

export const AddAddress = (props) => {
  const { t, i18n } = useTranslation();
  const [street, setStreet] = useState("");
  const [defaultAddress, setDefaultAddress] = useState("");
  const [tag, setTag] = useState("");
  const [zip, setZip] = useState("");
  const [note, setNote] = useState("");
  const [city, setCity] = useState(null);
  const [lat, setLat] = useState(30.0970063);
  const [lng, setLng] = useState(31.2992309);
  const [debouncedValue, setDebouncedValue] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedValue(street);
    }, 2000);

    return () => {
      clearTimeout(timerId);
    };
  }, [street]);

  // Function to handle input change
  const handleInputChange = (value) => {
    
    setStreet(value);
  };

  // Perform any action with debouncedValue
  useEffect(() => {
    if (debouncedValue !== '') {
      // Call your function here with debouncedValue
      console.log('User finished typing:', debouncedValue);
    }
  }, [debouncedValue]);

  useEffect(()=>{
    setLoading(true);
    if(props?.item){
      setStreet(props?.item?.street);
      setDefaultAddress(props?.item?.defaultAddress ? true : false);
      setTag(props?.item?.tag);
      setZip(props?.item?.zipCode);
      setNote(props?.item?.note);
      setCity(props?.item?.cities?.length ? props?.item?.cities?.find((city)=>city?.locale === i18n?.language)?.id : null)
      setLoading(false);
    }else{
      setLoading(false);
    }

  },[props.item?.id])

  const submitAddress = async () => {
    props.setLoadingFlag(true);
    let data = {
      street: street,
      mapMarker: {
        lat,
        "long":lng
      },
      defaultAddress: defaultAddress ? true : false,
      tag: tag,
      zipCode: zip,
      note: note,
      patient: TokenService.getUser()?.patient_id,
      cities: [parseInt(city)],
    };
    if (props.item?.id) {
      editData("/addresses", props.item?.id, { data }).then((response) => {
        props.setLoadingFlag(false);
        if (response) {
          props.setModalOpen(false);
          props.AddAddressToArray(response, props?.cities.filter((c)=>c.id === parseInt(city)), true, props?.index);
        }

      });
    } else {
      postData("/addresses", { data }, true).then((response) => {
        props.setLoadingFlag(false);
        if (response) {
          props.setModalOpen(false);
          props.AddAddressToArray(response, props?.cities.filter((c)=>c.id === parseInt(city)));
        }

      });
    }
  };
  
  const customStyles = {
    content: {
      position: "absolute",
      border: "1px solid rgb(204, 204, 204)",
      background: "rgb(238, 238, 238)",
      overflow: "auto",
      borderRadius: "10px",
      outline: "none",
      padding: "20px",
      width: "67%",
      height: "450px",
      maxWidth: "700px",
      top: "3rem",
      margin: "auto",
    },
    overlay: {
      position: "fixed",
      zIndex: 1020,
      top: 0,
      left: 0,
      width: "100vw",
      height: "100vh",
      background: "rgba(255, 255, 255, 0.75)",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      overflow: "auto",
    },
  };
  return (
    <ReactModal
      isOpen={props.modalOpen}
      contentLabel="Medical Question"
      style={customStyles}
      ariaHideApp={false}
    >
      {!loading ?
      <div className="position-relative mt-3">
      <img
        src="./img/close.svg"
        className="close-img"
        onClick={() => {
          props.setModalOpen(false);
        }}
      />
      <div className="form-group">
        <Map
          setLat={setLat}
          setLng={setLng}
          lng={lng}
          lat={lat}
          setStreet={setStreet}
          street={debouncedValue}
        />
        <div>
          <label>{`${t("address")}`}</label>
          <TextInput
            placeholder={`${t("address.enterstreet")}`}
            onChange={setStreet}
            value={street}
          />
        </div>
        <div>
          <label>{`${t("address.tag")}`}</label>
          <TextInput
            label={`${t("address.tag")}`}
            placeholder={`${t("address.entertag")}`}
            onChange={setTag}
            value={tag}
          />
        </div>
        <div>
          <label>{`${t("address.zipcode")}`}</label>
          <TextInput
            label={`${t("address.zipcode")}`}
            placeholder={`${t("address.enterzipcode")}`}
            onChange={setZip}
            value={zip}
          />
        </div>
        <div>
          <label>{`${t("address.note")}`}</label>
          <TextInput
            label={`${t("address.note")}`}
            placeholder={`${t("address.enternote")}`}
            onChange={setNote}
            value={note}
          />
        </div>
        <div>
          <label>{`${t("address.city")}`}</label>
          <SelectBox
            items={props.cities ? props.cities : []}
            placeholder={`${t("address.entercity")}`}
            onChange={setCity}
            type="region"
            divClass={"email-input select-padding"}
            formClass="border-0"
            valueIsId={true}
            value={city}
          />
        </div>
        <div className="my-3">
          <CheckBox
            label={`${t("address.default")}`}
            onChange={setDefaultAddress}
            selected={defaultAddress}
            value={true}
          />
        </div>
      </div>
    </div>
      : ''}
      <SignInUpButton
        state={!street || !city ? "greyed-out" : "default"}
        className={"book-button py-4 my-3 book-btn-modal"}
        name={props?.item?.id ? t("edit") :t("address.addaddress")}
        onClick={() => submitAddress()}
      />
    </ReactModal>
  );
};
