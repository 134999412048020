import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import { Star } from "../Star";
import { Text } from "../Text";
import "./style.css";

export const DoctorStar = ({ property1, className, starStarClassName, onClick, title }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "frame-12",
  });

  return (
    <div
      className={`doctor-star ${state.property1} ${className}`}
    >
      <Star
        className={`${state.property1 === "frame-13" ? "class" : "class-2"}`}
        img={state.property1 === "frame-12" ? "/img/star-1-3.svg" : undefined}
        star={state.property1 === "frame-13" ? "star-2" : "star-1"}
        star1={state.property1 === "frame-13" ? "/img/star-2-1.svg" : undefined}
        starClassName={starStarClassName}
      />
      <Text
        divClassName={`${state.property1 === "frame-13" && "class-3"}`}
        property1={state.property1 === "frame-13" ? "highlighted" : "default"}
        text={title + "?"}
        dispatchMouse={dispatch}
        onclick={onClick}
      />
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "mouse_enter":
      return {
        ...state,
        property1: "frame-13",
      };
    case "mouse_leave":
      return {
        ...state,
        property1: "frame-12",
      };
    default:
      break;

  }
  return state;
}

DoctorStar.propTypes = {
  property1: PropTypes.oneOf(["frame-12", "frame-13"]),
};
