import { getData } from "./apiService";

const searchForClinic = async (params, setResult, setLoadingFlag) => {
  setLoadingFlag(true);
  await getData("/doctor-services/clinic/search", params).then((response)=>{
    setResult(response);
    setLoadingFlag(false);
    return true
  }).catch((error) => {
    setLoadingFlag(false);
  });
};

const searchForHomevisit = async (params, setResult, setLoadingFlag) => {
  setLoadingFlag(true);
  await getData("/doctor-services/homevisit/search", params).then((response)=>{
    setResult(response);
    setLoadingFlag(false);
    return true
  }).catch((error) => {
    setLoadingFlag(false);
  });
};

const searchForTelehealth = async (params, setResult, setLoadingFlag) => {
  setLoadingFlag(true);
  await getData("/doctor-services/telehealth/search", params).then((response)=>{
    setResult(response);
    setLoadingFlag(false);
    return true
  }).catch((error) => {
    setLoadingFlag(false);
  });;
};

const SearchService = {
  searchForClinic,
  searchForTelehealth,
  searchForHomevisit,
};

export default SearchService;
