import React from "react";
import "./style.css";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { t } from "i18next";

export const PharmacySlider = ({
  property1,
  className,
  overlapGroupClassName,
  httpsLottiefilesClassName,
  httpsLottiefilesClassNameOverride,
}) => {
  const buttonStyle = {
    width: "30px",
    background: "none",
    border: "0px",
  };

  const properties = {
    prevArrow: (
      <button style={{ ...buttonStyle }}>
        <img src="./img/arrow_back_ios.svg" alt="back-arrow" />
      </button>
    ),
    nextArrow: (
      <button style={{ ...buttonStyle }}>
        <img src="./img/arrow_forward_ios.svg" alt="forward-arrow" />
      </button>
    ),
  };
  return (
    <Slide
      slidesToScroll={1}
      slidesToShow={1}
      indicators={true}
      {...properties}
    >      
    <div className={`pharmacy-slider `}>
        <div className="text-wrapper">{t("fast.process")}</div>
        <div className="text-wrapper-2">{t("order.in.seconds")}</div>
        <p className="p">
          {t("submit.your.order")}
          <br />
          {t("hassle.of.phone")}
        </p>
        <div className="overlap">
          <img
            className="https-lottiefiles"
            alt="Https lottiefiles"
            src="/img/https-lottiefiles-com-96844-fast-arrow.gif"
          />
        </div>
      </div>
      <div className={`pharmacy-slider primary-main-bg`}>
        <div className="text-wrapper-3">{t("intuitive.system")}</div>
        <div className="text-wrapper-4">{t("upload.prescription")}</div>
        <p className="our-smart-system">
          {t("our.smart.system")}
          <br />
          {t("prescription.down")}
        </p>
        <img
          className="https-app"
          alt="Https app"
          src="/img/https-app-lottiefiles-com-animation-eb1949d5-7fe4-40a8-a8ef-70.gif"
        />
      </div>
      <div className={`pharmacy-slider`}>
        <div className="text-wrapper">{t("trustworthy.providers")}</div>
        <div className="text-wrapper-2">{t("genuine.products")}</div>
        <p className="p">
          {t("all.products.medicine")}
          <br />
          {t("of.verified.pharmacies")}
        </p>
        <div className={`overlap-group-2 ${overlapGroupClassName}`}>
          <img
            className={`img ${httpsLottiefilesClassName}`}
            alt="Https lottiefiles"
            src="/img/https-lottiefiles-com-66849-medicine-strip-tablet-1.gif"
          />
          <img
            className={`https-lottiefiles-2 ${httpsLottiefilesClassNameOverride}`}
            alt="Https lottiefiles"
            src="/img/https-lottiefiles-com-84741-success-1.gif"
          />
        </div>
      </div>
    </Slide>
  );
};
