import axios from 'axios';
import { GOOGLE_MAPS_API_KEY } from '../shares/config'
const getLatAndLng = async (street, setLat, setLng, setMarker, setCenter) => {
    try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(street)}&key=${GOOGLE_MAPS_API_KEY}`
        );
  
        if (response.data.results.length > 0) {
          const { lat, lng } = response.data.results[0].geometry.location;
          setLat(lat);
          setLng(lng);
          setMarker({
            lat, lng
          })
          setCenter({
            lat, lng
          })
        } else {
          console.error('No results found for the provided street name');
        }
      } catch (error) {
        console.error('Error fetching coordinates:', error);
      }
};

const getStreet = async (lat, lng, setStreet ) => {
    try {
        const response = await axios.get(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`
        );
  
        if (response.data.results.length > 0) {
          const address = response.data.results[0].formatted_address;
          setStreet(address);
        } else {
          console.error('No results found');
        }
        
      } catch (error) {
        console.error('Error fetching address:', error);
      }
}


const MapService = {
    getStreet,
    getLatAndLng
};

export default MapService;
