import { useEffect, useState } from "react";
import { SubscriptionCard } from "../SubscriptionCard/SubscriptionCard";
import "./style.css";
import { AddFamilyMember } from "../AddFamilyMember/AddFamilyMember";
import PostingService from "../../services/posting.service";
import { useTranslation } from "react-i18next";
import validator from "validator";

const SubscriptionTab = ({ plan, setLoadingFlag, userData }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [dependents , setDependents] = useState([]);
  const [errors, setErrors] = useState({});
  const { t } = useTranslation();

  async function addFamilyMember() {
    let errorsObj = {};
    let newProfiles = [];

    if (phoneNumber && !validator.isMobilePhone(phoneNumber)) {
      errorsObj.phone = t("phone.validation.error");
    }

    const data = {
      firstname: firstName,
      lastname: lastName,
      mobileNumber: phoneNumber,
      subscription_id: plan?.id,
    };
    if (!Object.keys(errorsObj).length) {
      await PostingService.addFamilyMemberToPlan(
        setLoadingFlag,
        data,
        setModalOpen,
        setErrors
      ).then(()=>{
        newProfiles.push({
          firstname: firstName,
          lastname: lastName
        })
        dependents?.push({profiles: newProfiles})
      }).catch((error) => {
        if (error?.response?.status === 400) {
          errorsObj.general = t("addmember.message");
        } else {
          errorsObj.general = error?.message;
        }

        setLoadingFlag(false);
      });
    }

    setErrors(errorsObj);
  }

  useEffect(()=>{
    setLastName("");
    setFirstName("");
    setPhoneNumber("");
    setErrors({});
  },[modalOpen]);

  useEffect(()=>{
    setDependents(userData?.dependents);
  },[userData])

  return (
    <section className="sub-menu-overview ">
      {!plan?.name ? (
        <div className="m-auto align-items-center d-flex flex-column gap-4 ">
          <h1 className="plan-type-placeholder">
            {t("subscribtion.empty")}
          </h1>
          <a href="/personal-plans">
            <div className="navbar-button-subscribe">
              <div className="">{t("header.subscribe")}</div>
            </div>
          </a>
        </div>
      ) : (
        <div>
          <AddFamilyMember
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setPhoneNumber={setPhoneNumber}
            firstName={firstName}
            lastName={lastName}
            phoneNumber={phoneNumber}
            addFamilyMember={addFamilyMember}
            errors={errors}
          />
          <div className="return-home-btn d-flex flex-column">
            <SubscriptionCard plan={plan} setModalOpen={setModalOpen} dependents={dependents}/>
          </div>
        </div>
      )}
    </section>
  );
};

export default SubscriptionTab;
