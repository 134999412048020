import { t } from "i18next";
import "./style.css";
import { SignInUpButton } from "../SignInUpButton";
import { useNavigate } from "react-router-dom";

export const FeatureBanner = ({
  type,
  color,
  title1,
  title2,
  desc,
  desc2,
  imgSrc,
  dashboardFlag,
  urlLink
}) => {
  const navigate = useNavigate();
  return (
    <div className={`feature-banner d-flex flex-column ${color}`}>
      <div className={`frame d-flex ${type}-frame`}>
        <div className="tov">
          <div className="cost">{title1}</div>
          <div className="book-your-doctor">{title2}</div>
          <div className="avoid-the-hassle-container">
            <p className="avoid-the-hassle">{desc}</p>
            <p className="avoid-the-hassle">{desc2}</p>
          </div>
        </div>
        <div className="img-container">
          <img
            className={
              type === "home-visit" ? "home-isolated" : "dr-isolated-2"
            }
            alt={type}
            src={imgSrc}
          />
        </div>
      </div>
      <div>
          {dashboardFlag ? (
            <SignInUpButton
              name={t("booknow")}
              onClick={() => navigate(`${urlLink}`)}
              className="book-now-btn"
            />
          ) : (
            ""
          )}
        </div>
    </div>
  );
};
