import { editData } from "./apiService";

const startConsultation = async (id, setVideoConfig ,setLoadingFlag) => {
  setLoadingFlag(true);
  const data = {
    data: {
      status: "started",
    },
  };

  editData("/consultations", id, data).then((res)=>{
    setVideoConfig(res?.data?.attributes?.data);
    setLoadingFlag(false);
  });
};

const endConsultation = async (id, setLoadingFlag) => {
  const data = {
    data: {
      status: "ended",
    },
  };

  editData("/consultations", id, data).then((res)=>console.log(res));
};

const ConsultationService = {
  startConsultation,
  endConsultation
};

export default ConsultationService;
