import PropTypes from "prop-types";
import React from "react";
import { useReducer } from "react";
import "./style.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export const PhoneNumberInput = ({ property1, className, onChange ,error, placeholder, defaultValue, noMargin }) => {
  const [state, dispatch] = useReducer(reducer, {
    property1: property1 || "empty",
  });

  return (
    <div className={noMargin ? '' :"mb-4"}>
      <PhoneInput
        placeholder={placeholder}
        className={`email-input ${state.property1} ${className}`}
        onClick={() => {
          dispatch("click");
        }}
        onChange={(e)=>onChange(e)}
        value={defaultValue}
      />
      <p className="text-danger  h6 position-absolute  text-start">{error}</p>
    </div>
  );
};

function reducer(state, action) {
  switch (action) {
    case "click":
      return {
        ...state,
        property1: "phone",
      };
    default:
      break;
  }

  return state;
}

PhoneNumberInput.propTypes = {
  property1: PropTypes.oneOf(["empty", "phone"]),
};
