import { useTranslation } from "react-i18next";
import TokenService from "../../services/token.service";
import { formatter } from "../../helpers/currencyFormatter";
import { Tooltip as ReactTooltip, Tooltip } from "react-tooltip";
import { TooltipPlace } from "../Tooltip/TooltipPlace";
import "react-tooltip/dist/react-tooltip.css";

export const DoctorCard = ({ item, setModalOpen, setItem, type }) => {
  const { t, i18n } = useTranslation();

  function clickBook() {
      setItem(item);
      setModalOpen(true);
      document.body.style.overflow = "hidden";
  }

  return (
    <div className="doctor-card">
      <div className="image-container-card">
        <img
          className="image-placeholder-icon"
          alt="doctor-img"
          src={
            item?.profilePicture
              ? item?.profilePicture
              : "./img/doctor-placeholder.jpg"
          }
        />
      </div>
      <div className="doctor-name-placeholder">
        <div
          className="doctor-name-placeholder1"
          data-tooltip-id={`my-tooltip`}
          data-tooltip-content={
            i18n.language === "ar" && item.firstname_ar
              ? `د.${item.firstname_ar} ${item.lastname_ar}`
              : `Dr. ${item.firstname} ${item.lastname}`
          }
        >
          {i18n.language === "ar" && item.firstname_ar
            ? `د.${item.firstname_ar} ${item.lastname_ar}`
            : `Dr. ${item.firstname} ${item.lastname}`}
        </div>
        {/* <TooltipPlace
          id={`tool-tip${item?.id}`}
          place="top"
          content={
            i18n.language === "ar" && item.firstname_ar
              ? `د.${item.firstname_ar} ${item.lastname_ar}`
              : `Dr. ${item.firstname} ${item.lastname}`
          }
        /> */}
      </div>

      <div className="card-section-container width100per">
        <div className="specialty-placeholder width100per">
          <div className="specialty-placeholder1 width100per">
            <img className="location-icon" alt="" src="/img/doctor_icon.png" />
            <div
              className="specialty width100per"
              data-tooltip-id={`my-tooltip`}
              data-tooltip-content={`${
                item.specialities?.filter((spec) =>
                  i18n?.language
                    ? spec?.locale === i18n?.language
                    : spec?.locale === "en"
                )?.length > 1
                  ? `${
                      item.specialities?.filter((spec) =>
                        i18n?.language
                          ? spec?.locale === i18n?.language
                          : spec?.locale === "en"
                      )[0]?.name
                    } , ${
                      item.specialities?.filter((spec) =>
                        i18n?.language
                          ? spec?.locale === i18n?.language
                          : spec?.locale === "en"
                      )[1]?.name
                    }`
                  : item.specialities?.filter((spec) =>
                      i18n?.language
                        ? spec?.locale === i18n?.language
                        : spec?.locale === "en"
                    )?.length
                  ? item.specialities?.filter((spec) =>
                      i18n?.language
                        ? spec?.locale === i18n?.language
                        : spec?.locale === "en"
                    )[0]?.name
                  : "-"
              }`}
            >{`${
              item.specialities?.filter((spec) =>
                i18n?.language
                  ? spec?.locale === i18n?.language
                  : spec?.locale === "en"
              )?.length > 1
                ? `${
                    item.specialities?.filter((spec) =>
                      i18n?.language
                        ? spec?.locale === i18n?.language
                        : spec?.locale === "en"
                    )[0]?.name
                  } , ${
                    item.specialities?.filter((spec) =>
                      i18n?.language
                        ? spec?.locale === i18n?.language
                        : spec?.locale === "en"
                    )[1]?.name
                  }`
                : item.specialities?.filter((spec) =>
                    i18n?.language
                      ? spec?.locale === i18n?.language
                      : spec?.locale === "en"
                  )?.length
                ? item.specialities?.filter((spec) =>
                    i18n?.language
                      ? spec?.locale === i18n?.language
                      : spec?.locale === "en"
                  )[0]?.name
                : "-"
            }`}</div>
          </div>
        </div>
        <div className="costlocation">
          <div className="price-placeholder">
            <img src="./img/money.png" className="location-icon" />
            <div className="egp">{`${item.doctorServiceFee} ${t("egp")}`}</div>
            <div className="cost">
              {item?.doctor_titles?.length > 0 && item?.doctor_titles[0]?.title
                ? i18n.language === "ar"
                  ? `${item?.doctor_titles[0]?.title_ar}`
                  : `${item?.doctor_titles[0]?.title}`
                : ""}
            </div>
          </div>
          {type === "clinic" && (
            <div className="location-details">
              <div className="pindistance">
                <img
                  src="./img/clinic_location.png"
                  className="location-icon"
                />
                <div className="cost">{`${item.distance} ${
                  item?.cities?.length > 0 && item?.cities[0]?.id
                    ? "," +
                      item.cities.filter(
                        (city) => city.locale === i18n.language
                      )[0]?.name
                    : ""
                }`}</div>
              </div>
            </div>
          )}
          {type === "home-visit" && (
            <div className="location-details">
              <div className="pindistance width100per">
                <img
                  src="./img/clinic_location.png"
                  className="location-icon"
                />
                <div className="cost">
                  {item?.homevisitCities?.length > 0
                    ? item?.homevisitCities
                        ?.filter((city) => city.locale === i18n.language)
                        .map((cityItem, cityKey) => (
                          <span>{`${cityItem?.name} ${
                            cityKey + 1 !==
                            item?.homevisitCities?.filter(
                              (city) => city.locale === i18n.language
                            )?.length
                              ? ","
                              : ""
                          }`}</span>
                        ))
                    : "-"}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div onClick={() => clickBook()} className="width100per mt-auto">
        <div className="book-button">
          <div className="book">{t("doctor.book")}</div>
        </div>
      </div>
      <div className="d-flex">
        {item.score?.average
          ? Array.from(Array(parseInt(item.score?.average)), (e, i) => {
              return (
                <img
                  key={i + "-score"}
                  className="star-rating-icon"
                  alt=""
                  src="/img/score_star.svg"
                />
              );
            })
          : Array.from(Array(5), (e, i) => {
              return (
                <img
                  key={i + "-score"}
                  className="star-rating-icon"
                  alt=""
                  src="/img/score_star.svg"
                />
              );
            })}
        {Array.from(
          Array(item.score?.average ? 5 - parseInt(item.score?.average) : 0),
          (e, i) => {
            return (
              <img
                key={i + "-dark-star"}
                className="star-rating-icon"
                alt=""
                src="/img/dark-star.svg"
              />
            );
          }
        )}
      </div>
    </div>
  );
};
