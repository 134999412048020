const calculateTimeDifference = (startDate, endDate, setTimeDifference) => {
    const startTime = !startDate ? new Date().getTime() : new Date(startDate).getTime();
    const endTime = !endDate ? new Date().getTime() : new Date(endDate).getTime();

    if (isNaN(startTime) || isNaN(endTime)) {
      return 'unknown';
    }

    if (startTime >= endTime) {
      return false;
    }

    const timeDifference = Math.abs(endTime - startTime);
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    return{hours, minutes };
  };

  const DateService = {
   calculateTimeDifference
  };
  
  export default DateService;