import React, { useState } from "react";
import { SignInUpButton } from "../../components/SignInUpButton";
import { useTranslation } from "react-i18next";
import { NumberInput } from "../../components/NumberInput/NumberInput";
import "./style.css";
import AuthService from "../../services/auth.service";
import { useNavigate } from "react-router-dom";

export const Verification = ({ setLoadingFlag }) => {
  const { t } = useTranslation();
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();

  function verify() {
    const params = { "filters[code][$eq]": otp };
    AuthService.validateOTP(params, navigate, setLoadingFlag);
  }
  
  return (
    <div className="sign-in-flow d-flex height77vh">
      <div className="group">
        <p className="p mb-3">{t("auth.verifyaccount")}</p>
        <NumberInput
          value={otp}
          onChange={setOtp}
          placeholder={t("auth.enterotp")}
        />
        <p className="text-align-start">{t("auth.otpmessage")}</p>
        <p className="text-align-start">{t("auth.resendotp")} <a className="link pointer" onClick={(e)=>{e.preventDefault();AuthService.resendOTP(setLoadingFlag)}}>{t("auth.clickhere")}</a></p>
        <SignInUpButton
          className="sign-in-up-button-instance mb-3 sign-in-up-button"
          onClick={() => verify()}
          state={otp ? "default" : "greyed-out"}
          name={t("auth.verify")}
        />
      </div>
    </div>
  );
};
